import { useMatches } from "react-router-dom"
import "./style.css"
import { Container } from "react-bootstrap"

function BreadCrumbs() {
  let matches = useMatches()
  let crumbs: any = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match: any) => Boolean(match.handle?.crumb))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match: any) => match.handle.crumb(match.data))

  const numberOfCrumbs = crumbs.length
  console.log("numberOfCrumbs: " + numberOfCrumbs)

  return (
    <>
      <Container>
        <nav className="breadcrumbs">
          {crumbs && <>Du är här:&nbsp;</>}
          {crumbs &&
            crumbs.map((crumb: any, index: number) => {
              let crumbLink = crumb.props.to
              let crumbLabel = crumb.props.children
              console.log("crumbLink: " + crumbLink)
              console.log("crumbLabel: " + crumbLabel)
              return (
                <>
                  {index > 0 && (
                    <span style={{ color: "#999999" }}>&nbsp;| </span>
                  )}

                  {index !== numberOfCrumbs - 1 && (
                    <a style={{ color: "#999999" }}>{crumb}</a>
                  )}
                  {index === numberOfCrumbs - 1 && <span>{crumbLabel}</span>}
                </>
              )
            })}
        </nav>
      </Container>
    </>
  )
}

export default BreadCrumbs

import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Information om infrastruktur</h1>
        <div className="ingress">
          En nätinnehavare är, under vissa förutsättningar, skyldig att lämna
          information om infrastruktur.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        En nätinnehavare ska på begäran av en bredbandsutbyggare
                        lämna information om sin fysiska infrastrukturs
                        lokalisering och sträckning, typ och nuvarande
                        användning samt kontaktuppgifter som behövs för att
                        bredbandsutbyggaren ska kunna lämna en begäran om
                        tillträde till nätinnehavaren. Bredbandsutbyggarens
                        begäran ska vara skriftlig och ange vilket geografiskt
                        område som begäran avser. Bredbandsutbyggaren bör begära
                        mottagningsbevis eller på annat sätt försäkra sig om att
                        nätinnehavaren har tagit emot begäran.
                      </p>
                      <p>
                        En nätinnehavare som har fått en begäran om information
                        ska lämna informationen på proportionella,
                        icke-diskriminerande och öppet redovisade villkor.
                        Informationen ska tillhandahållas senast två månader
                        från det att begäran togs emot.
                      </p>
                      <p>
                        Nätinnehavaren får begränsa tillgången till
                        informationen till följd av tystnadsplikt eller, om det
                        är nödvändigt, med hänvisning till nätets driftsäkerhet,
                        allmän säkerhet, totalförsvaret eller Sveriges säkerhet
                        i övrigt, folkhälsa eller affärs- och
                        driftsförhållanden. I det allmännas verksamhet ska i
                        stället offentlighets- och sekretesslagen (2009:400)
                        tillämpas.&nbsp;
                      </p>
                      <p>
                        Om informationen i fråga finns tillgänglig genom en
                        informationstjänst, t.ex. Ledningskollen, kan
                        skyldigheten fullgöras genom att bredbandsutbyggaren
                        hänvisas dit.
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

import { Link } from "react-router-dom";

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Förordning om gigabitinfrastruktur</h1>
        <div className="ingress">
          EU beslutat om en ny reglering som syftar till att underlätta
          utbyggnaden av nät med mycket hög kapacitet. Förordningen om
          gigabitinfrastruktur kommer att ersätta utbyggnadslagen.
        </div>
        <p>
          Nyligen publicerades den nya EU-förordningen om gigabitinfrastruktur
          (GIA). GIA är en utveckling av och ersätter utbyggnadsdirektivet som i
          Sverige införlivades genom utbyggnadslagen (2016:534). Förordningen
          avser att bemöta den ständigt växande efterfrågan på snabbare, mer
          tillförlitlig, och mer dataintensiv konnektivitet genom att underlätta
          utbyggnaden av nät med mycket hög kapacitet.
        </p>
        <p>
          <strong>Mer infrastruktur ingår</strong>
        </p>
        <p>
          För att uppnå detta ska förordningen ytterligare minska byråkratin,
          kostnaderna och den administrativa bördan i samband med utbyggnaden av
          nät med mycket hög kapacitet. Förenklade och digitaliserade
          tillståndsförfaranden med skärpta skyndsamhetskrav ska säkerställa att
          aktörer får tillgång till fysisk infrastruktur så som kanalisation,
          master och annan fysisk infrastruktur. Möjligheten att begära
          tillträde utvidgas till att omfatta även offentlig fysisk
          infrastruktur som inte ingår i ett nätverk, såsom byggnader och väg-
          och gatuutrustning (exempelvis ljusstolpar, trafikljus, vägskyltar,
          hållplatser och tunnelbanestationer). Operatörer kommer därmed att
          kunna begära tillträde till en utökad mängd fysisk infrastruktur.
          Förordningen ska även förbättra förutsättningarna för samordning av
          bygg- och anläggningsprojekt. <br />
          <br />
          <p>
            <strong>En plats för informationen</strong>
          </p>
        </p>
        <p>
          Den utvidgade möjligheten som lagen ger för operatörer som vill bygga
          ut nät med mycket hög kapacitet innebär att fler nätoperatörer och
          fler fysiska objekt kommer att omfattas av lagen. Därmed ska fler
          aktörer tillhandahålla information om en utökad mängd av fysiska
          objekt. Denna information ska vara tillgänglig via en så kallad
          central informationspunkt, vilket innebär att operatörer som vill
          bygga ut nät med mycket hög kapacitet ska kunna söka upp informationen
          via en och samma webbplats.
        </p>
        <p>
          Den centrala informationspunkten behöver inte vara värd för
          informationen utan ska framför allt säkerställa hänvisning till de
          aktörer som har rådighet över informationen. Det ska även finnas
          möjlighet att ta del av annan relevant information via den centrala
          informationspunkten som hantering av tillstånd, ledningsrätt och
          övriga tillämpliga villkor för ärendet.
        </p>
        <p>
          <strong>PTS roll</strong>
        </p>
        <p>
          PTS har idag ansvar för övervakningen av att utbyggnadslagen följs.
          Det är inte ännu beslutat vilken myndighet som ska ha det ansvaret för
          förordningen om gigabitinfrastruktur. PTS följer frågan.
        </p>
        <br />
        <br />
        <br />
      </div>
    </>
  );
}
export default Page;

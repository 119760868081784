import { Link } from "react-router-dom";

import "./style.css";
import { Container } from "react-bootstrap";

function Header({
  logo = "",
  logoLinkTo = "",
  logoHeight = "48px",
  brandText = "PTS e-tjänster",
  brandTextColor = "black",
  brandLinkTo = "https://www.pts.se/kontakt/alla-e-tjanster/",
  brandTextSize = "1.5rem",
  containerPadding = "24px 0",
  containerAlignItems = "flex-end",
}: {
  logo?: string;
  logoLinkTo?: string;
  logoHeight?: string;
  brandText?: string;
  brandTextColor?: string;
  brandLinkTo?: string;
  brandTextSize?: string;
  containerPadding?: string;
  containerAlignItems?: string;
}) {
  return (
    <>
      <header>
        {logoLinkTo && (
          <Container
            style={{
              padding: containerPadding,
              alignItems: containerAlignItems,
            }}
          >
            <>
              <Link to={logoLinkTo}>
                <img src={logo} alt="Logo" height={logoHeight} />
              </Link>
              <Link
                to={brandLinkTo}
                className="headerText"
                style={{ fontSize: brandTextSize, color: brandTextColor }}
              >
                {brandText}
              </Link>
            </>
          </Container>
        )}
      </header>
    </>
  );
}

export default Header;

// import { Link } from "react-router-dom"
// import ptsLogo from "../../../app/assets/Images/logo.png"

// import "./style.css"

// function Header() {
//   return (
//     <header>
//       <div className="container">
//         <div className="col-md-12 col-sm-12">
//           <div className="container headercontainer">
//             <div className="col-md-12 col-sm-12">
//               <div className="top">
//                 <div className="logo">
//                   <a
//                     href="https://pts.se"
//                     title="PTS E-tj&auml;nster, startsida"
//                     className="logo"
//                   >
//                     <img src={ptsLogo} alt="PTS E-tj&auml;nster, startsida" />
//                   </a>
//                 </div>
//                 <div className="services">
//                   <a href="https://pts.se/sv/e-tjanster/">PTS e-tjänster</a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </header>
//   )
// }

// export default Header

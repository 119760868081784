import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Infrastruktur i byggnad</h1>
        <div className="ingress">
          De som äger eller annars råder över fysisk infrastruktur för
          elektronisk kommunikation i en byggnad är skyldiga att på begäran
          medge tillträde till infrastrukturen.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p style={{ margin: "0cm 0cm 10pt" }}>
                        Tillträde till fysisk infrastruktur för elektronisk
                        kommunikation som finns i en byggnad kan medges
                        till&nbsp;exempelvis&nbsp;accesspunkt, kanalisation och
                        annan passiv infrastruktur som är avsedd för att
                        möjliggöra bredbandsåtkomst. För att kunna få tillträde
                        krävs det att nyanläggning av ytterligare infrastruktur
                        i byggnaden är olönsam eller fysiskt ogenomförbar.
                      </p>
                      <p style={{ margin: "0cm 0cm 10pt" }}>
                        En begäran om tillträde får avslås om den inte är
                        rimlig, till exempel&nbsp;om det skulle medföra märkbara
                        driftstörningar för befintliga tjänster. Tillträde till
                        infrastruktur i en byggnad kan i vissa fall&nbsp;nekas
                        om tillträde redan ges till ett befintligt elektroniskt
                        kommunikationsnät med hög kapacitet på objektiva, öppet
                        redovisade, proportionerliga och icke-diskriminerande
                        villkor.&nbsp;
                      </p>
                      <p>
                        De som äger eller annars råder över, dvs. har tillgång
                        till och bestämmer över,&nbsp;infrastrukturen&nbsp;får
                        ställa upp villkor för tillträdet, till
                        exempel&nbsp;avseende ersättning för tillträdet eller
                        att byggnaden ska återställas i det skick den var innan
                        tillträdet. &nbsp;
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Rättigheter och skyldigheter</h1>
        <div className="ingress">
          Utbyggnadslagen medför rättigheter för bredbandsutbyggare och
          skyldigheter för ägare av infrastruktur (i lagen definierade som
          nätinnehavare).
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        <strong>Bredbandsutbyggare</strong> har under vissa
                        förutsättningar rätt att:
                      </p>
                      <ul>
                        <li>få information om fysisk infrastruktur,</li>
                        <li>
                          få information om pågående och planerade bygg- och
                          anläggningsprojekt,
                        </li>
                        <li>
                          få möjlighet att undersöka infrastrukturen på plats
                          samt
                        </li>
                        <li>
                          erhålla tillträde till fysisk infrastruktur eller
                        </li>
                        <li>
                          få möjlighet att samordna sitt utbyggnadsprojekt med
                          nätinnehavares bygg- eller anläggningsprojekt.
                        </li>
                      </ul>
                      <p>
                        Utöver detta har bredbandsutbyggare i vissa fall rätt
                        att få tillträde till fysisk infrastruktur för
                        elektronisk kommunikation i en byggnad.
                      </p>
                      <p>
                        Bredbandsutbyggare har även vissa skyldigheter, som att
                        lämna en skriftlig begäran till nätinnehavaren
                        eller&nbsp;följa särskilda villkor som nätinnehavaren
                        anger för att medge en begäran.
                      </p>
                      <p>
                        <strong>Nätinnehavare</strong> är under vissa
                        förutsättningar skyldiga att:
                      </p>
                      <ul>
                        <li>lämna ut information om fysisk infrastruktur,</li>
                        <li>
                          lämna ut information om pågående och planerade bygg-
                          och anläggningsprojekt,
                        </li>
                        <li>medge undersökning på plats,</li>
                        <li>
                          ge tillträde till fysisk infrastruktur&nbsp;samt&nbsp;
                        </li>
                        <li>
                          ge tillträde till fysisk infrastruktur för elektronisk
                          kommunikation i byggnad.
                        </li>
                      </ul>
                      <p>
                        Nätinnehavare är även&nbsp;skyldiga att medverka samt om
                        nödvändigt ansöka om tillstånd som är nödvändiga för
                        bredbandsutbyggnaden.
                      </p>
                      <p>
                        <strong>
                          Nätinnehavare som dessutom är ett offentligt organ
                        </strong>{" "}
                        har, utöver de skyldigheter och rättigheter som gäller
                        för alla nätinnehavare, även skyldighet att i många fall
                        medge samordning av bygg- och anläggningsprojekt.
                        Detsamma gäller nätinnehavare vars bygg- och
                        anläggningsprojekt helt eller delvis finansieras av
                        offentliga medel.
                      </p>
                      <p>
                        Dessutom är dessa nätinnehavare skyldiga att informera
                        om bygg- och anläggningsprojekten utan krav på begäran
                        från en bredbandsutbyggare.
                      </p>
                      <p>
                        <em>Att neka begäran</em>
                      </p>
                      <p>
                        Begäran om tillträde, information samordning eller
                        undersökning på plats får avslås under vissa
                        förutsättningar.
                      </p>
                      <p>
                        Nätinnehavare har även rätt att i vissa fall ställa upp
                        villkor för medgivande av bredbandsutbyggares begäran.
                      </p>
                      <p>
                        När nätinnehavare överväger att avslå en begäran ska de
                        först överväga om uppställandet av ett villkor kan
                        medföra ett medgivande istället för ett avslag.
                      </p>
                      <p>
                        Mer information finns under{" "}
                        <Link to="/information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt/">
                          information
                        </Link>
                        , <Link to="/tilltraede/">tillträde</Link> och{" "}
                        <Link to="/samordning/">samordning</Link>.
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text"></div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

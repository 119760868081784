import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Information om bygg- och anläggningsprojekt</h1>
        <div className="ingress">
          En nätinnehavare är, under vissa förutsättningar, skyldig att lämna
          information om infrastruktur och bygg- eller anläggningsprojekt.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        <em>
                          Information om offentligt finansierande bygg- eller
                          anläggningsprojekt
                        </em>
                      </p>
                      <p>
                        En nätinnehavare som utgör ett offentligt organ, eller
                        som utför ett bygg- eller anläggningsprojekt som
                        finansieras helt eller delvis med offentliga medel, ska
                        göra information om ett planerat eller pågående bygg-
                        eller anläggningsprojekt tillgänglig elektroniskt. Den
                        information som ska göras tillgänglig är lokalisering
                        och typ av projekt, berörda delar av nätet, beräknat
                        datum för inledning av projektet och projektets
                        varaktighet samt kontaktuppgifter till nätinnehavaren.
                        Skyldigheten att lämna information gäller inte för bygg-
                        och anläggningsprojekt av mindre betydelse.
                      </p>
                      <p>
                        Informationen ska göras tillgänglig i sådan tid att en
                        bredbandsutbyggare kan begära samordning av bygg- och
                        anläggningsprojekt. Nätinnehavaren får begränsa
                        tillgången till informationen till följd av
                        tystnadsplikt eller, om det är nödvändigt, med
                        hänvisning till nätets driftsäkerhet, allmän säkerhet,
                        totalförsvaret eller Sveriges säkerhet i övrigt,
                        folkhälsa eller affärs- och driftsförhållanden. I det
                        allmännas verksamhet ska i stället offentlighets- och
                        sekretesslagen (2009:400) tillämpas.
                      </p>
                      <p>
                        Nätinnehavaren ska uppdatera informationen inom två
                        veckor från det att en förändring inträffat.
                      </p>
                      <p>
                        Om informationen finns tillgänglig genom en
                        informationstjänst, t.ex. Ledningskollen, kan
                        skyldigheten fullgöras genom att bredbandsutbyggaren
                        hänvisas dit.
                      </p>
                      <p>
                        <em>
                          Information om planerade bygg- eller
                          anläggningsprojekt på begäran
                        </em>
                      </p>
                      <p>
                        En nätinnehavare är skyldig att på begäran från en
                        bredbandsutbyggare ge information om ett planerat bygg-
                        eller anläggningsprojekt, oavsett hur detta finansieras.
                        Den information som ska lämnas på begäran är
                        lokalisering och typ av projekt, berörda delar av nätet,
                        beräknat datum för inledning av projektet och projektets
                        varaktighet samt kontaktuppgifter till nätinnehavaren.
                        Begäran ska vara skriftlig och ange vilket geografiskt
                        område som begäran avser. Bredbandsutbyggaren bör begära
                        mottagningsbevis eller på annat sätt försäkra sig om att
                        nätinnehavaren har tagit emot begäran.&nbsp;
                      </p>
                      <p>
                        Skyldigheten gäller i förhållande till ett bygg- eller
                        anläggningsprojekt för vilket tillstånd har beviljats,
                        tillstånd har sökts eller för vilket nätinnehavaren
                        avser att ansöka om tillstånd för inom sex månader. När
                        det gäller bygg- eller anläggningsprojekt som inte
                        kräver något tillstånd ska nätinnehavaren lämna
                        information om de bygg- och anläggningsprojekt som
                        nätinnehavaren planerar att påbörja inom det närmaste
                        året. Skyldigheten att lämna information gäller inte för
                        bygg- och anläggningsprojekt av mindre betydelse.
                      </p>
                      <p>
                        Nätinnehavaren ska uppdatera informationen senast två
                        veckor från det att en förändring inträffat.&nbsp;
                      </p>
                      <p>
                        Nätinnehavaren ska på proportionella,
                        icke-diskriminerande och öppet redovisade villkor
                        tillhandahålla informationen. Nätinnehavaren får
                        begränsa tillgången till informationen till följd av
                        tystnadsplikt eller, om det är nödvändigt, med
                        hänvisning till nätets driftsäkerhet, allmän säkerhet,
                        totalförsvaret eller Sveriges säkerhet i övrigt,
                        folkhälsa eller affärs- och driftsförhållanden. I det
                        allmännas verksamhet ska i stället offentlighets- och
                        sekretesslagen (2009:400) tillämpas.
                      </p>
                      <p>
                        Om informationen finns tillgänglig genom en
                        informationstjänst, t.ex. Ledningskollen, kan
                        skyldigheten fullgöras genom att bredbandsutbyggaren
                        hänvisas dit.
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Överenskommelse och tvist</h1>
        <div className="ingress">
          Utgångspunkten är att bredbandsutbyggaren och nätinnehavaren ska komma
          överens om utlämnande av information eller undersökning på plats.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        Om parterna inte kommer överens kan både
                        bredbandsutbyggaren och nätinnehavaren vända sig till
                        PTS och begära en prövning av det som är tvistigt dem
                        emellan.{" "}
                        <span>
                          Om någon av parterna begär tvistlösning ska PTS som
                          huvudregel fatta ett beslut inom två månader från det
                          att en fullständig begäran inkom till myndigheten.
                        </span>
                      </p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Tillträde</h1>
        <div className="ingress">
          Nätinnehavare är skyldiga att på begäran medge tillträde till fysisk
          infrastruktur för utbyggnad av fast eller trådlöst bredband med
          kapacitet över 30 Mbit/s.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        Fysisk infrastruktur är dels&nbsp;passiva nätdelar
                        utanför byggnader&nbsp;såsom kanalisationer, master,
                        tillsynsbrunnar, kopplingsskåp, stolpar m.m. dels passiv
                        infrastruktur för elektronisk kommunikation i byggnader
                        såsom&nbsp;accesspunkten och&nbsp;kanalisation.
                      </p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Överenskommelse och tvist</h1>
        <div className="ingress">
          Utgångspunkten är att bredbandsutbyggaren och nätinnehavaren ska komma
          överens om samordningen och eventuella villkor som ska gälla.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        Om parterna inte kommer överens kan både
                        bredbandsutbyggaren och nätinnehavaren vända sig till
                        PTS och begära en prövning av det som är tvistigt dem
                        emellan. Tvisten kan avse att samordning nekats eller de
                        villkor som nätinnehavaren ställt upp.
                      </p>
                      <p>
                        Om någon av parterna begär tvistlösning ska PTS som
                        huvudregel fatta ett beslut inom två månader från det
                        att en fullständig begäran inkom till myndigheten.
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Kontakt</h1>
        <div className="ingress"></div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        Information om PTS{" "}
                        <a href="https://www.pts.se/sv/Bransch/Regler/Tillsyn/">
                          tillsyn
                        </a>
                        .
                      </p>
                      <p>
                        Information om PTS{" "}
                        <a href="http://www.pts.se/sv/Bransch/Regler/Tvistlosning/">
                          tvistlösning
                        </a>
                        .
                      </p>
                      <p>
                        Frågor om Utbyggnadslagen:{" "}
                        <a href="mailto:utbyggnad@pts.se">utbyggnad@pts.se</a>.
                      </p>
                      <p>
                        Mer{" "}
                        <a href="http://www.pts.se/sv/Kontakt/">
                          kontaktuppgifter
                        </a>{" "}
                        till PTS.
                      </p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

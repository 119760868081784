import { Link, useNavigate } from "react-router-dom";
import CardList from "../../components/CardList/CardList";

const content = {
  cards: [
    {
      title: "Information om infrastruktur och bygg- och anläggningsprojekt",
      description:
        "Om nätinnehavares skyldighet att lämna information till bredbandsbyggare.",
      link: "Information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt/",
      image: "webcopy/media/1069/bild-2-final.jpg",
    },
    {
      title: "Tillträde",
      description:
        "Om nätinnehavares skyldighet att på begäran medge tillträde till fysisk infrastruktur.",
      link: "tilltraede",
      image: "/media/1071/bild-3-final.jpg",
    },
    {
      title: "Samordning",
      description:
        "Om offentliga organs, eller den som utför ett bygg- eller anläggningsprojekt helt eller delvis finansierat med offentliga medel, skyldighet att medge samordning av bygg- och anläggningsprojekt.",
      link: "samordning",
      image: "/media/1072/bild-4-final.jpg",
    },
  ],
};

function Page() {
  const navigate = useNavigate();

  const linkClick = (link: string) => {
    navigate(link);
  };

  return (
    <>
      <div className="col-md-9">
        {/* <h1>Utbyggnadsportalen</h1> */}
        <p style={{ color: "red" }}>
          <strong>
            Observera att utbyggnadslagen fr.o.m. den 12 november 2025 ersätts
            av den nya förordningen om gigabitinfrastruktur. För mer
            information,{" "}
            <a href="/forordning-om-gigabit-infrastruktur">klicka här.</a>
          </strong>
        </p>
        <div className="ingress">
          Här hittar du information om utbyggnadslagen.
        </div>
        <div>
          <p>
            Med stöd av lagen (2016:534) om åtgärder för utbyggnad av
            bredbandsnät kan den som vill bygga ut fast eller trådlöst bredband
            få tillträde till andras infrastruktur eller samordna sin utbyggnad
            med andra – allt för att bredbandsutbyggnad ska bli billigare.
          </p>
          <p>
            Här på Utbyggnadsportalen finns samlad information om vilka
            rättigheter bredbandsutbyggare har och vilka skyldigheter
            nätinnehavare har enligt lagen. Lagen berör aktörer både inom och
            utom telekomsektorn.
          </p>
          <p>
            Här finns information om befintlig infrastruktur och kommande
            byggprojekt, om tillstånd och regler.{" "}
            <span
              style={{
                fontSize: "10.5pt",
                fontFamily: "Verdana",
                color: "#141313",
              }}
            >
              Här finns också länkar till olika webbtjänster som har information
              om infrastruktur, t.ex.{" "}
              <a href="https://www.ledningskollen.se/">
                <u>Ledningskollen</u>
              </a>
              .
            </span>
          </p>
          <p> </p>
        </div>

        <div className="col-md-12">
          <div className="umb-grid">
            <div className="grid-section">
              <div>
                <div>
                  <CardList data={content} linkFunc={linkClick} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Page;

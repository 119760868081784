import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Tidsfrister</h1>
        <div className="ingress">
          När en begäran mottagits av en nätinnehavare börjar en tidsfrist att
          löpa.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        En begäran om tillträde ska vara skriftlig (kan vara
                        e-post) och innehålla en redogörelse för utbyggnaden
                        eller anläggandet samt en tidsplan för projektet i den
                        utsträckning som är relevant för att bedöma om tillträde
                        ska medges eller avslås.&nbsp;Bredbandsutbyggaren bör
                        begära ett mottagningsbevis för att försäkra sig om att
                        tidsfristen för nätinnehavarens svar börjar löpa.&nbsp;
                      </p>
                      <p>
                        Nätinnehavaren ska så snart som möjligt, dock senast två
                        månader från mottagandet av en fullständig begäran om
                        tillträde, skriftligen meddela bredbandsutbyggaren om
                        begäran medges eller avslås.
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Ledningskollen</h1>
        <div className="ingress">
          Ledningskollen är en gratis webbtjänst som underlättar kommunikation
          mellan de som äger nedgrävd infrastruktur och de som vill veta var
          sådan finns.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        Med{" "}
                        <a href="https://www.ledningskollen.se/">
                          <u>Ledningskollen</u>
                        </a>
                        &nbsp;kan den som planerar att gräva, få besked om vem
                        som äger infrastruktur inom ett visst område.
                      </p>
                      <p>
                        I Ledningskollen finns ledningsägare över hela landet.
                        Med ledningsägare avses i Ledningskollen de som äger
                        ledningar och nedgrävd infrastruktur, det är alltså ett
                        vidare begrepp än nätinnehavare.
                      </p>
                      <p>
                        Bredbandsutbyggare kan använda Ledningskollen för att få
                        information om en nätinnehavares fysiska infrastruktur
                        för att sedan kunna begära tillträde, samordning eller
                        för att begära undersökning på plats eller eventuellt
                        begära ytterligare information.
                      </p>
                      <p>
                        Ledningskollen kan även användas som ett verktyg för
                        samordning och informationsutbyte vid planering och
                        projektering av framtida projekt. Förfrågningar kan
                        läggas in redan under ett planläggnings- eller
                        projekteringsläge.
                      </p>
                      <p>I Ledningskollen finns följande olika ärendetyper:</p>
                      <ul>
                        <li>
                          <strong>Ledningsanvisning</strong> kan användas för
                          att få information från berörda ledningsägare om var
                          ledningarna finns nedgrävda.
                        </li>
                        <li>
                          <strong>Projektering</strong> kan användas av den som
                          ska projektera och behöver få underlag i form av
                          kartor eller cad-ritningar över befintlig
                          infrastruktur på en viss plats. OBS! Underlaget som
                          ledningsägaren lämnar i projekteringsfasen får inte
                          användas för grävning.
                        </li>
                        <li>
                          <strong>Samordning</strong> kan användas av den som
                          vill komma i kontakt med ledningsägare på en viss
                          plats för att undersöka möjligheten till samordning,
                          samt för de som vill informera om planerade och
                          pågående bygg- och anläggningsprojekt.
                        </li>
                        <li>
                          <strong>Planärende</strong> kan användas av en kommun
                          eller annan myndighet som vill komma i kontakt med
                          andra ledningsägare i ett område för att informera om
                          ett planärende.
                        </li>
                      </ul>
                      <p>
                        Mer information om hur Ledningskollen kan användas för
                        nätinnehavare eller bredbandsutbyggare utifrån Lagen om
                        åtgärder för utbyggnad av bredbandsnät finns på
                        Ledningskollens webbplats. Där finns också information
                        om vilka ledningsägare som finns med i
                        Ledningskollen.&nbsp;
                      </p>
                      <p>
                        Ledningskollen är öppen för alla och gratis. Den som
                        vill använda Ledningskollen måste registrera sig och
                        skriftligt godkänna de användarvillkor som gäller för
                        tjänsten.
                      </p>
                      <p>
                        Ledningskollen drivs av PTS och finansieras av PTS,
                        Svenska Kraftnät och Trafikverket.
                      </p>
                      <p>
                        <em>Regionala tjänster</em>
                      </p>
                      <p>
                        I Ledningskollen och Geodataportalen finns information
                        om ledningar över hela Sverige. Det finns också tjänster
                        som tillhandahåller information om ledningar inom en
                        viss kommun eller en viss stad. Dessa tjänster hittar du
                        på respektive kommuns eller stad webbplats.
                      </p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

import Navbar from "../OffcanvasNavigation/OffcanvasNavigation"

function MainMenu({
  links,
  nameOfService = ""
}: {
  links?: any
  nameOfService?: string
}) {
  return <>{links && <Navbar nameOfService={nameOfService} links={links} />}</>
}

export default MainMenu

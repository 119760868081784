import { Link } from "react-router-dom";
import RootLayout from "../../../pages/root/Root";
import ErrorPage from "../../../pages/root/Error";
import StartPage from "../../../pages/utbyggnadsportalen/startsida";
import InfoInfastructureAndBuildProjects from "../../../pages/utbyggnadsportalen/Information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt/Information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt";
import Access from "../../../pages/utbyggnadsportalen/tilltraede/Tilltraede";
import Coordination from "../../../pages/utbyggnadsportalen/samordning/samordning";
import QandA from "../../../pages/utbyggnadsportalen/fraagor-och-svar";
import Contact from "../../../pages/utbyggnadsportalen/kontakt";
import MoreInfoBroadband from "../../../pages/utbyggnadsportalen/mer-information-om-bredband";
import RulesAndRegulations from "../../../pages/utbyggnadsportalen/lagar-och-regler";
import Permits from "../../../pages/utbyggnadsportalen/tillstaand/tillstaand";
import AffectedByLaw from "../../../pages/utbyggnadsportalen/vilka-omfattas-av-lagen/vilka-omfattas-av-lagen";
import Rights from "../../../pages/utbyggnadsportalen/vilka-omfattas-av-lagen/raettigheter-och-skyldigheter";
import Infrastructure from "../../../pages/utbyggnadsportalen/vilka-omfattas-av-lagen/infrastruktur";
import Requests from "../../../pages/utbyggnadsportalen/vilka-omfattas-av-lagen/begaeran-och-svar";
import InfoInfrastrucure from "../../../pages/utbyggnadsportalen/Information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt/information-om-infrastruktur";
import Investigate from "../../../pages/utbyggnadsportalen/Information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt/undersoekning-av-fysisk-infrastruktur";
import InfoBuildProjects from "../../../pages/utbyggnadsportalen/Information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt/information-om-bygg-och-anlaeggningsprojekt";
import Agreements from "../../../pages/utbyggnadsportalen/Information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt/oeverenskommelse-och-tvist";
import ManagementCheck from "../../../pages/utbyggnadsportalen/Information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt/ledningskollen";
import OtherServices from "../../../pages/utbyggnadsportalen/Information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt/andra-tjaenster";
import InfrastructureOutsideBuildning from "../../../pages/utbyggnadsportalen/tilltraede/infrastruktur-utanfoer-byggnad";
import InfrastructureInsideBuilding from "../../../pages/utbyggnadsportalen/tilltraede/infrastruktur-i-byggnad";
import Timespans from "../../../pages/utbyggnadsportalen/tilltraede/tidsfrister";
import AgreementsAccess from "../../../pages/utbyggnadsportalen/tilltraede/oeverenskommelse-och-tvist";
import CoordinationTimespans from "../../../pages/utbyggnadsportalen/samordning/tidsfrister";
import CoordinationAgreements from "../../../pages/utbyggnadsportalen/samordning/oeverenskommelse-och-tvist";
import Kommunen from "../../../pages/utbyggnadsportalen/tillstaand/kommunen";
import Lantmaeteriet from "../../../pages/utbyggnadsportalen/tillstaand/lantmaeteriet";
import Laensstyrelsen from "../../../pages/utbyggnadsportalen/tillstaand/laensstyrelsen";
import Trafikverket from "../../../pages/utbyggnadsportalen/tillstaand/trafikverket";
import OtherLicensers from "../../../pages/utbyggnadsportalen/tillstaand/oevriga-tillstaandgivare";
import PTSDecision from "../../../pages/utbyggnadsportalen/pts-beslut";
import ForordningOmGigabitinfrastruktur from "../../../pages/utbyggnadsportalen/forordning-om-gigabitinfrastuktur";

export const routesArray = [
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    handle: {
      crumb: () => <Link to="/">Startsidan</Link>,
    },
    children: [
      { index: true, element: <StartPage /> },
      {
        path: "Information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt",

        handle: {
          crumb: () => (
            <Link to="Information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt">
              Information om infrastruktur och bygg- och anläggningsprojekt
            </Link>
          ),
        },
        children: [
          {
            index: true,
            element: <InfoInfastructureAndBuildProjects />,
          },
          {
            path: "information-om-infrastruktur",
            element: <InfoInfrastrucure />,
            handle: {
              crumb: () => (
                <Link to="information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt/information-om-infrastruktur">
                  Information om infrastruktur
                </Link>
              ),
            },
          },
          {
            path: "undersoekning-av-fysisk-infrastruktur",
            element: <Investigate />,
            handle: {
              crumb: () => (
                <Link to="information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt/undersoekning-av-fysisk-infrastruktur">
                  Undersökning av fysisk infrastruktur
                </Link>
              ),
            },
          },
          {
            path: "information-om-bygg-och-anlaeggningsprojekt",
            element: <InfoBuildProjects />,
            handle: {
              crumb: () => (
                <Link to="information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt/information-om-bygg-och-anlaeggningsprojekt">
                  Information om bygg- och anläggningsprojekt
                </Link>
              ),
            },
          },
          {
            path: "oeverenskommelse-och-tvist",
            element: <Agreements />,
            handle: {
              crumb: () => (
                <Link to="information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt/oeverenskommelse-och-tvist">
                  Överenskommelse och tvist
                </Link>
              ),
            },
          },
          {
            path: "ledningskollen",
            element: <ManagementCheck />,
            handle: {
              crumb: () => (
                <Link to="information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt/ledningskollen">
                  Ledningskollen
                </Link>
              ),
            },
          },
          {
            path: "andra-tjaenster",
            element: <OtherServices />,
            handle: {
              crumb: () => (
                <Link to="information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt/andra-tjaenster">
                  Andra tjänster
                </Link>
              ),
            },
          },
        ],
      },
      {
        path: "Tilltraede",

        handle: {
          crumb: () => <Link to="Tilltraede">Tillträde</Link>,
        },
        children: [
          {
            index: true,
            element: <Access />,
          },
          {
            path: "infrastruktur-utanfoer-byggnad",
            element: <InfrastructureOutsideBuildning />,
            handle: {
              crumb: () => (
                <Link to="tilltraede/infrastruktur-utanfoer-byggnad">
                  Infrastruktur utanför byggnad
                </Link>
              ),
            },
          },
          {
            path: "infrastruktur-i-byggnad",
            element: <InfrastructureInsideBuilding />,
            handle: {
              crumb: () => (
                <Link to="tilltraede/infrastruktur-i-byggnad">
                  Infrastruktur i byggnad
                </Link>
              ),
            },
          },
          {
            path: "tidsfrister",
            element: <Timespans />,
            handle: {
              crumb: () => <Link to="tilltraede/tidsfrister">Tidsfrister</Link>,
            },
          },
          {
            path: "oeverenskommelse-och-tvist",
            element: <AgreementsAccess />,
            handle: {
              crumb: () => (
                <Link to="tilltraede/oeverenskommelse-och-tvist">
                  Överenskommelse och tvist
                </Link>
              ),
            },
          },
        ],
      },
      {
        path: "samordning",

        handle: {
          crumb: () => <Link to="samordning">Samordning</Link>,
        },
        children: [
          {
            index: true,
            element: <Coordination />,
          },
          {
            path: "tidsfrister",
            element: <CoordinationTimespans />,
            handle: {
              crumb: () => <Link to="samordning/tidsfrister">Tidsfrister</Link>,
            },
          },
          {
            path: "oeverenskommelse-och-tvist",
            element: <CoordinationAgreements />,
            handle: {
              crumb: () => (
                <Link to="samordning/oeverenskommelse-och-tvist">
                  Överenskommelse och tvist
                </Link>
              ),
            },
          },
        ],
      },
      {
        path: "fraagor-och-svar",
        element: <QandA />,
        handle: {
          crumb: () => <Link to="fraagor-och-svar">Frågor och svar</Link>,
        },
      },
      {
        path: "kontakt",
        element: <Contact />,
        handle: {
          crumb: () => <Link to="kontakt">Kontakt</Link>,
        },
      },
      {
        path: "mer-information-om-bredband",
        element: <MoreInfoBroadband />,
        handle: {
          crumb: () => (
            <Link to="mer-information-om-bredband">
              Mer information om bredband
            </Link>
          ),
        },
      },
      {
        path: "lagar-och-regler",
        element: <RulesAndRegulations />,
        handle: {
          crumb: () => <Link to="lagar-och-regler">Lagar och regler</Link>,
        },
      },
      {
        path: "forordning-om-gigabit-infrastruktur",
        element: <ForordningOmGigabitinfrastruktur />,
        handle: {
          crumb: () => (
            <Link to="forordning-om-gigabit-infrastruktur">
              Förordning om gigabitinfrastruktur
            </Link>
          ),
        },
      },
      {
        path: "tillstaand",

        handle: {
          crumb: () => <Link to="tillstaand">Tillstånd</Link>,
        },
        children: [
          {
            index: true,
            element: <Permits />,
          },
          {
            path: "kommunen",
            element: <Kommunen />,
            handle: {
              crumb: () => <Link to="tillstaand/kommunen">Kommunen</Link>,
            },
          },
          {
            path: "lantmaeteriet",
            element: <Lantmaeteriet />,
            handle: {
              crumb: () => (
                <Link to="tillstaand/lantmaeteriet">Lantmäteriet</Link>
              ),
            },
          },
          {
            path: "laensstyrelsen",
            element: <Laensstyrelsen />,
            handle: {
              crumb: () => (
                <Link to="tillstaand/laensstyrelsen">Länsstyrelsen</Link>
              ),
            },
          },
          {
            path: "trafikverket",
            element: <Trafikverket />,
            handle: {
              crumb: () => (
                <Link to="tillstaand/trafikverket">Trafikverket</Link>
              ),
            },
          },
          {
            path: "oevriga-tillstaandgivare",
            element: <OtherLicensers />,
            handle: {
              crumb: () => (
                <Link to="tillstaand/oevriga-tillstaandgivare">
                  Övriga tillståndgivare
                </Link>
              ),
            },
          },
        ],
      },
      {
        path: "pts-beslut",
        element: <PTSDecision />,
        handle: {
          crumb: () => <Link to="pts-beslut">PTS beslut</Link>,
        },
      },
      {
        path: "vilka-omfattas-av-lagen",
        handle: {
          crumb: () => (
            <Link to="vilka-omfattas-av-lagen">Vilka omfattas av lagen</Link>
          ),
        },
        children: [
          {
            index: true,
            element: <AffectedByLaw />,
          },
          {
            path: "raettigheter-och-skyldigheter",
            element: <Rights />,
            handle: {
              crumb: () => (
                <Link to="vilka-omfattas-av-lagen/raettigheter-och-skyldigheter">
                  Rättigheter och skyldigheter
                </Link>
              ),
            },
          },
          {
            path: "infrastruktur",
            element: <Infrastructure />,
            handle: {
              crumb: () => (
                <Link to="vilka-omfattas-av-lagen/infrastruktur">
                  Infrastruktur
                </Link>
              ),
            },
          },

          {
            path: "begaeran-och-svar",
            element: <Requests />,
            handle: {
              crumb: () => (
                <Link to="vilka-omfattas-av-lagen/begaeran-och-svar">
                  Begäran och svar
                </Link>
              ),
            },
          },
        ],
      },
    ],
  },
];

import { Link, Outlet, useLocation, useMatches } from "react-router-dom"

//import "../../app/assets/css/screen.css"
// import "../../app/assets/css/leftmenu.css"
//import "../../app/assets/css/styles.css"
//import "../../app/assets/css/utbyggnads.css"
import Header from "../../components/Header/Header"
//import Footer from "../../components/Footer/Footer"
import Footer from "../../components/Footer/Footer2"
import MainMenu from "../../components/MainMenu/MainMenu"
import SubMenu from "../../components/SubMenu/SubMenu"
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs"
import { links } from "./menuLinks"
import { Col, Container, Row } from "react-bootstrap"
import { getRoutesForNavigations } from "../../app/routes/getRoutesForNavigations"
import "./style.css"
import { useEffect } from "react"

function RootLayout() {
  const location = useLocation()
  const hideBreadCrumbsOnStartpage = true
  let matches = useMatches()
  let crumbs: any = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match: any) => Boolean(match.handle?.crumb))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match: any) => match.handle.crumb(match.data))

  matches.map((match: any) => console.log(match.pathname))

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <>
      <div className="d-flex flex-column h-100" style={{ minHeight: "100vh" }}>
        <Header logo="/Images/logo.png" logoLinkTo="https://pts.se" />
        <Container>
          <menu>
            <MainMenu links={links} nameOfService="Utbyggnadsportalen" />
          </menu>
        </Container>
        {hideBreadCrumbsOnStartpage && location.pathname !== "/" && (
          <BreadCrumbs />
        )}

        <main>
          <Container>
            <article>
              <Row>
                <Col className="md-4">
                  <aside>
                    <nav>
                      <SubMenu links={links} />
                    </nav>
                  </aside>
                </Col>
                <Outlet />
              </Row>
            </article>
          </Container>
        </main>
        <Footer />
      </div>
    </>
  )
}
export default RootLayout

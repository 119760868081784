import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Trafikverket</h1>
        <div className="ingress">Tillstånd som behövs hos Trafikverket.</div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        Information om ansökningar och förfrågningar som rör
                        utbyggnadslagen finns här på{" "}
                        <a
                          rel="noopener"
                          href="https://www.trafikverket.se/tjanster/ansok-om/tillstand/ansokan-om-atgarder-for-utbyggnad-av-bredbandsnat/"
                          target="_blank"
                        >
                          Trafikverkets hemsida
                        </a>
                        .
                      </p>
                      <p>
                        I samband med tillträde till fysisk infrastruktur,
                        fysisk undersökning eller genomföra en samordning med
                        ett av våra bygg- och anläggningsprojekt kan ytterligare
                        tillstånd och/eller avtal behövas från Trafikverket.
                        Detta kommer att framgå av Trafikverkets beslut.
                      </p>
                      <p>
                        Inplacering i Trafikverkets master eller torn innebär
                        helt enkelt att du kan få plats för din
                        kommunikationsutrustning, utan att bygga egen mast. Du
                        använder din egen utrustning och i första hand ditt eget
                        teknikhus.&nbsp;
                      </p>
                      <p>
                        Information kring inplacering i master finns också på{" "}
                        <a
                          rel="noopener"
                          href="https://www.trafikverket.se/tjanster/ansok-om/Inplacering-i-master/"
                          target="_blank"
                        >
                          Trafikverkets hemsida
                        </a>
                        .
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

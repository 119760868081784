import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Lantmäteriet</h1>
        <div className="ingress">
          Lantmäteriet prövar frågor om åtkomst till mark.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        Lantmäteriet prövar frågor om åtkomst till mark, t.ex.
                        bildande av ledningsrätter, gemensamhetsanläggningar och
                        servitut för bredband.&nbsp;Lantmäteriet är i övrigt den
                        myndighet som kartlägger Sverige. Till myndighetens
                        uppgifter hör att registrera och säkra ägandet av alla
                        fastigheter samt hantera deras gränser.
                      </p>
                      <p>
                        För mer information om markfrågor, bredband och hur en
                        lantmäteriförrättning går till så kan ni besöka
                        myndighetens hemsida.
                      </p>
                      <p>
                        <a href="http://www.lantmateriet.se/sv/Fastigheter/Andra-fastighet/Tillgang-till-annans-mark/">
                          Tillgång till annans mark
                        </a>
                      </p>
                      <p>
                        <a
                          href="https://www.lantmateriet.se/sv/Fastigheter/Andra-fastighet/Tillgang-till-annans-mark/bredband/"
                          target="_blank"
                        >
                          {" "}
                          Bredband på landsbygden
                        </a>
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

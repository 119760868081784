export const links = [
  {
    path: "vilka-omfattas-av-lagen",
    label: "Vilka omfattas av lagen",
    href: "/vilka-omfattas-av-lagen",

    children: [
      {
        path: "raettigheter-och-skyldigheter",
        label: "Rättigheter och skyldigheter",
        href: "/vilka-omfattas-av-lagen/raettigheter-och-skyldigheter",
      },
      {
        path: "infrastruktur",
        label: "Infrastruktur",
        href: "/vilka-omfattas-av-lagen/infrastruktur",
      },

      {
        path: "begaeran-och-svar",
        label: "Begäran och svar",
        href: "/vilka-omfattas-av-lagen/begaeran-och-svar",
      },
    ],
  },
  {
    label: "Infrastruktur/ bygg- och anläggningsprojekt",
    href: "/Information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt",

    children: [
      {
        label: "Information om infrastruktur",
        href: "/information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt/information-om-infrastruktur",
      },
      {
        label: "Undersökning av fysisk infrastruktur",
        href: "/information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt/undersoekning-av-fysisk-infrastruktur",
      },
      {
        label: "Information om bygg- och anläggningsprojekt",
        href: "/information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt/information-om-bygg-och-anlaeggningsprojekt",
      },
      {
        path: "oeverenskommelse-och-tvist",
        label: "Överenskommelse och tvist",
        href: "/information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt/oeverenskommelse-och-tvist",
      },
      {
        path: "ledningskollen",
        label: "Ledningskollen",
        href: "/information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt/ledningskollen",
      },
      {
        path: "andra-tjaenster",
        label: "Andra tjänster",
        href: "/information-om-infrastruktur-och-bygg-och-anlaeggningsprojekt/andra-tjaenster",
      },
    ],
  },
  {
    path: "Tilltraede",
    label: "Tillträde",
    href: "/tilltraede",

    children: [
      {
        path: "infrastruktur-utanfoer-byggnad",
        label: "Infrastruktur utanför byggnad",
        href: "/tilltraede/infrastruktur-utanfoer-byggnad",
      },
      {
        path: "infrastruktur-i-byggnad",
        label: "Infrastruktur i byggnad",
        href: "/tilltraede/infrastruktur-i-byggnad",
      },
      {
        path: "tidsfrister",
        label: "Tidsfrister",
        href: "/tilltraede/tidsfrister",
      },
      {
        path: "oeverenskommelse-och-tvist",
        label: "Överenskommelse och tvist",
        href: "/tilltraede/oeverenskommelse-och-tvist",
      },
    ],
  },
  {
    path: "samordning",
    label: "Samordning",
    href: "/samordning",

    children: [
      {
        path: "tidsfrister",
        label: "Tidsfrister",
        href: "/samordning/tidsfrister",
      },
      {
        path: "oeverenskommelse-och-tvist",
        label: "Överenskommelse och tvist",
        href: "/samordning/oeverenskommelse-och-tvist",
      },
    ],
  },
  {
    path: "tillstaand",
    label: "Tillstånd",
    href: "/tillstaand",

    children: [
      {
        path: "kommunen",
        label: "Kommunen",
        href: "/tillstaand/kommunen",
      },
      {
        path: "lantmaeteriet",
        label: "Lantmäteriet",
        href: "/tillstaand/lantmaeteriet",
      },
      {
        path: "laensstyrelsen",
        label: "Länsstyrelsen",
        href: "/tillstaand/laensstyrelsen",
      },
      {
        path: "trafikverket",
        label: "Trafikverket",
        href: "/tillstaand/trafikverket",
      },
      {
        path: "oevriga-tillstaandgivare",
        label: "Övriga tillståndgivare",
        href: "/tillstaand/oevriga-tillstaandgivare",
      },
    ],
  },
  {
    path: "lagar-och-regler",
    label: "Lagar och regler",
    href: "/lagar-och-regler",
  },

  {
    path: "pts-beslut",
    label: "PTS beslut",
    href: "/pts-beslut",
  },
  {
    path: "forordning-om-gigabit-infrastruktur",
    label: "Förordning om gigabitinfrastruktur",
    href: "/forordning-om-gigabit-infrastruktur",
  },
  {
    path: "fraagor-och-svar",
    label: "Frågor och svar",
    href: "/fraagor-och-svar",
  },
  {
    path: "mer-information-om-bredband",
    label: "Mer information om bredband",
    href: "/mer-information-om-bredband",
  },
  {
    path: "kontakt",
    label: "Kontakt",
    href: "/kontakt",
  },
];

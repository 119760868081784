import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Tillstånd</h1>
        <div className="ingress">
          Den som vill bygga ut eller anlägga ett bredbandsnät kan behöva flera
          olika tillstånd och därför behöva ha kontakt med flera olika
          myndigheter.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        Förfarandet för att ansöka om tillstånd kan vara olika
                        hos myndigheterna och det underlag som ska lämnas in
                        till myndigheterna kan variera.
                      </p>
                      <p>
                        För att underlätta för bredbandsutbyggare att hitta
                        information om vilka tillstånd som kan bli aktuella att
                        söka, hur det går till att ansöka och hur en komplett
                        ansökan ska se ut finns här kort information från
                        berörda myndigheter samt länkar till deras hemsidor.
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Undersökning av fysisk infrastruktur</h1>
        <div className="ingress">
          En nätinnehavare är, under vissa förutsättningar, skyldig att låta en
          bredbandsutbyggare undersöka fysisk infrastruktur på plats.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        En bredbandutbyggare kan begära att på plats få
                        undersöka sådan fysisk infrastruktur som den senare kan
                        komma att begära tillträde till. Bredbandsutbyggarens
                        begäran ska vara skriftlig och ange vilken infrastruktur
                        som begäran avser. Syftet med en undersökning på plats
                        är att en bredbandsutbyggare ska kunna bedöma
                        förutsättningarna för att i ett senare skede begära
                        tillträde till nätinnehavarens fysiska infrastruktur.
                        Bredbandsutbyggaren bör begära mottagningsbevis eller på
                        annat sätt försäkra sig om att nätinnehavaren har tagit
                        emot begäran.
                      </p>
                      <p>
                        En nätinnehavare ska medge en begäran på proportionella,
                        icke-diskriminerande och öppet redovisade villkor.
                        Nätinnehavaren får avslå bredbandsutbyggarens begäran om
                        den begärda undersökningen inte är rimlig.
                        Nätinnehavaren får begränsa undersökningen till följd av
                        tystnadsplikt eller, om det är nödvändigt, med
                        hänvisning till nätets driftsäkerhet, allmän säkerhet,
                        totalförsvaret eller Sveriges säkerhet i övrigt,
                        folkhälsa eller affärs- och driftsförhållanden. I det
                        allmännas verksamhet tillämpas i stället offentlighets-
                        och sekretesslagen (2009:400).
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Tidsfrister</h1>
        <div className="ingress">
          När en begäran mottagits av en nätinnehavare börjar en tidsfrist att
          löpa.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        En begäran om samordning ska göras senast en månad innan
                        nätinnehavararen ska ansöka om nödvändiga tillstånd för
                        sitt bygg- eller anläggningsprojekt. När det gäller
                        projekt som inte kräver något tillstånd får det avgöras
                        i varje enskilt fall när en begäran om samordning senast
                        ska göras.
                      </p>
                      <p>
                        Nätinnehavaren ska senast en månad från mottagandet av
                        en begäran skriftligen meddela bredbandsutbyggaren om
                        begäran om samordning medges eller avslås. Vid
                        medgivande ska eventuella villkor för samordningen anges
                        och vid avslag ska de omständigheter som ligger till
                        grund för avslaget anges.
                      </p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

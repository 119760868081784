import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Andra tjänster</h1>
        <div className="ingress">
          I Geodataportalen går det att söka efter vilka geodata som finns
          tillgängliga via olika organisationers tjänster och data.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        <a
                          href="https://www.geodata.se/GeodataExplorer/index.jsp?loc=sv"
                          title="Geodataportalen"
                        >
                          Geodataportalen
                        </a>{" "}
                        kan användas till att:
                      </p>
                      <ul>
                        <li>söka och hitta geografiska datakällor</li>
                        <li>
                          visa detaljer för alla upptäckta datakällor och
                          information om hur du får tillgång till dem
                        </li>
                        <li>
                          direkt titta på de data och kartresurser du hittat
                        </li>
                        <li>
                          skapa, ladda upp och administrera metadata för geodata
                          och tjänster producerade i din organisation.
                        </li>
                      </ul>
                      <p>
                        Med geodata avses geografisk information, dvs.
                        beskrivningar av sådant som har ett geografiskt läge,
                        till exempel byggnader, sjöar och vägar men också
                        vegetation och befolkning. Geodataportalen är öppen för
                        alla och det krävs inget avtal för att titta och söka i
                        portalen. Geodataportalen är också&nbsp;gratis att
                        använda,&nbsp;men det kan vara avgiftsbelagt att ta del
                        av den geografiska miljöinformationen hos de
                        informationsansvariga myndigheterna.&nbsp;
                      </p>
                      <p>
                        För att publicera metadata i portalen krävs
                        registrering. Det finns en nationell metadataprofil som
                        är en specifikation och vägledning för att enhetligt
                        beskriva data och tjänster som ingår i
                        Geodataportalen.&nbsp;
                      </p>
                      <p>Geodataportalen förvaltas av Lantmäteriet.</p>
                      <p>
                        <em> Regionala tjänster</em>
                      </p>
                      <p>
                        I Ledningskollen och Geodataportalen finns information
                        om ledningar över hela Sverige. Det finns också tjänster
                        som tillhandahåller information om ledningar inom en
                        viss kommun eller en viss stad. Dessa tjänster hittar du
                        på respektive kommuns eller stad webbplats.
                      </p>
                      <p>
                        <em>Trafikverket</em>
                      </p>
                      <p>
                        För information om Trafikverkets fysiska infrastruktur
                        och bygg- och anläggningsprojekt se{" "}
                        <a
                          rel="noopener"
                          href="https://www.trafikverket.se/tjanster/ansok-om/tillstand/ansokan-om-atgarder-for-utbyggnad-av-bredbandsnat/"
                          target="_blank"
                        >
                          Trafikverkets hemsida
                        </a>
                        .&nbsp;
                      </p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Kommunen</h1>
        <div className="ingress">
          Etablering av bredbandsnät i kommunal mark.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        Den som vill anlägga bredbandsnät i mark som ägs av en
                        kommun behöver, på samma sätt som i privatägd mark, få
                        rätt att nyttja marken. Detta kan lösas på olika sätt,
                        t.ex. genom tecknande av nyttjanderätts- eller
                        servitutsavtal eller genom upplåtelse av ledningsrätt.
                        Nyttjande av kommunal allmän platsmark för
                        ledningsdragning regleras ofta i ett generellt
                        nyttjanderättsavtal (s.k. markavtal).
                      </p>
                      <p>
                        Mer information om kommunernas hantering av markavtal
                        och om ledningar i kommunal mark finns hos{" "}
                        <a
                          rel="noopener"
                          href="https://skr.se/skr/ekonomijuridik/juridik/fastighetsrattallmanochspeciell/ledningarikommunalmark.2269.html"
                          target="_blank"
                        >
                          Sveriges Kommuner och Regioner
                        </a>
                        .&nbsp;
                      </p>
                      <p>
                        Innan grävning i kommunal mark kan påbörjas behövs i
                        regel ett tillstånd från kommunen (ofta benämnt
                        grävningstillstånd). Grävningstillstånden regleras inte
                        i lag utan varje kommun beslutar i egenskap av markägare
                        själv om sina riktlinjer och villkor för att tillåta
                        grävning i kommunens mark. Att kommunerna ställer krav
                        på grävningstillstånd beror bl.a. på att de vill kunna
                        minska störningarna för trafikanter och allmänheten,
                        åstadkomma lämplig lokalisering av ledningar och
                        effektivt utnyttjande av utrymmet i marken, underlätta
                        samordning av olika arbeten och möjliggöra anpassning av
                        villkoren vid långa avtal.
                      </p>
                      <p>
                        Många kommuner ställer krav på att ledningsägaren
                        genomför samråd med övriga ledningsägare och berörda
                        kommunala förvaltningar innan eller i samband med att
                        ansökan om grävningstillstånd lämnas in.
                      </p>
                      <p>
                        Vid arbeten som berör trafikleder krävs normalt också en
                        trafikanordningsplan (TA-plan) som ska granskas och
                        godkännas av kommunen. En trafikanordningsplan
                        innehåller fakta om grävarbetet och hur det ska märkas
                        ut.
                      </p>
                      <p>
                        Grävarbeten kan även medföra behov av att tillfälligt
                        ändra eller införa trafikföreskrifter. Det kan t.ex.
                        handla om att förbjuda parkering inom ett arbetsområde
                        eller införa stopplikt. Tillfälliga lokala
                        trafikföreskrifter beslutas av kommunen på ansökan av
                        den som ska utföra grävarbetet.
                      </p>
                      <p>
                        Användning av mark utanför arbetsområdet för t.ex.
                        upplag eller uppställning av bodar och containers kan
                        kräva tillstånd av polismyndigheten enligt 3 kap 1 §
                        ordningslagen. För sådan användning kan kommunen ta ut
                        särskild avgift enligt kommunal taxa.
                      </p>
                      <p>
                        Information om kommunens regler och rutiner för grävning
                        i kommunens mark finns att läsa på de flesta kommuners
                        hemsida. Lämpliga sökord: ”grävningstillstånd”,
                        ”grävtillstånd”, ”schakttillstånd”, ”öppningstillstånd”
                        eller ”trafikanordningsplan”. Alla kommuners hemsidor
                        finns samlade hos{" "}
                        <a
                          rel="noopener"
                          href="https://skr.se/skr/tjanster/kommunerochregioner/kommunerlista.1246.html"
                          target="_blank"
                        >
                          Sveriges Kommuner och Regioner
                        </a>
                        .&nbsp;
                      </p>
                      <p>
                        Vägran att teckna markavtal eller ett beslut att inte
                        medge grävningstillstånd kan inte överklagas, vilket
                        innebär att om en överenskommelse inte kan nås får andra
                        vägar sökas, t.ex. ledningsrätt. Ibland kan möjligheten
                        att nyttja befintlig fysisk infrastruktur eller svart
                        fiber vara ett alternativ till att teckna markavtal med
                        kommunen.
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

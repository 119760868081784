import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Information om infrastruktur och bygg- och anläggningsprojekt</h1>
        <div className="ingress">
          En nätinnehavare är under vissa förutsättningar skyldig att lämna
          information om infrastruktur och bygg- eller anläggningsprojekt. En
          nätinnehavare är också skyldig att under vissa förutsättningar ge
          bredbandsutbyggaren möjlighet att undersöka infrastruktur på plats.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        En nätinnehavare är under vissa förutsättningar skyldig
                        att lämna information om infrastruktur och bygg- eller
                        anläggningsprojekt. Nätinnehavare som är offentliga
                        organ, eller nätinnehavare som utför ett byggprojekt som
                        är helt eller delvis finansierat med offentliga medel,
                        är skyldiga att tillgängliggöra information om planerade
                        och pågående bygg- och anläggningsprojekt.
                      </p>
                      <p>
                        Det finns även en skyldighet för nätinnehavaren att
                        under vissa förutsättningar ge bredbandsutbyggaren
                        möjlighet att undersöka infrastruktur på plats.
                      </p>
                      <p>
                        <em>Informationstjänster</em>
                      </p>
                      <p>
                        Om informationen finns tillgänglig via en
                        informationstjänst kan skyldigheten att lämna
                        information om infrastruktur och bygg- eller
                        anläggningsprojekt fullgöras genom att
                        bredbandsutbyggaren hänvisas dit. En sådan
                        informationstjänst är{" "}
                        <a href="https://www.ledningskollen.se/">
                          Ledningskollen
                        </a>{" "}
                        (se under rubriken Ledningskollen). En annan
                        informationstjänst är{" "}
                        <a href="https://www.geodata.se/GeodataExplorer/index.jsp?loc=sv">
                          Geodataportalen
                        </a>{" "}
                        (se under rubriken Andra tjänster).
                      </p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

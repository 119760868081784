import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Länsstyrelsen</h1>
        <div className="ingress">
          Att tänka på när du ska anlägga bredband. Funderar du eller din
          organisation på att gräva för bredbandsnät? Då finns det vissa saker
          som du behöver känna till innan du påbörjar arbetet. Särskild hänsyn
          ska tas i skyddade områden och på mark som misstänks vara förorenad.
          Det finns också söktjänster som du kan använda för att få mer
          information om marken där du planerar att gräva.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        Som exploatör och beställare är du är ansvarig för att
                        vara informerad om vad som gäller i skyddade områden. Du
                        är också ansvarig för att ta reda på om marken är eller
                        misstänks vara förorenad.&nbsp;
                      </p>
                      <p>
                        <em>
                          Hur vet jag om ett område är skyddat eller förorenat?
                        </em>
                      </p>
                      <p>
                        Enklast är så klart att undvika områden som är skyddade
                        eller misstänkt förorenade. Samtidigt kan det vara svårt
                        att veta vilka bestämmelser som finns för marken där du
                        tänkt gräva.
                      </p>
                      <p>
                        Om du planerar att placera bredbandsnätet i eller i
                        närheten av skyddade områden, som till exempel
                        fornlämningar, vattenområden, naturreservat, förorenade
                        områden eller småbiotoper är det viktigt att du
                        kontaktar länsstyrelsen för att få veta vad som är
                        möjligt. Mycket av denna information finns att tillgå på{" "}
                        <a href="http://www.lansstyrelsen.se">
                          Länsstyrelsens webbplats
                        </a>
                        .&nbsp;
                      </p>
                      <p>
                        <em>Söktjänsterna Fornsök och WebbGIS</em>
                      </p>
                      <p>
                        På länsstyrelsernas webbplatser finns söktjänster där du
                        kan få mer information och underlag för att kunna bedöma
                        om och när du behöver kontakta myndigheten.
                      </p>
                      <p>
                        <em>Fornsök</em>
                      </p>
                      <p>
                        Via söktjänsten Fornsök (FMIS){" "}
                        <a href="http://www.raa.se">www.raa.se</a> hittar du
                        registrerade fornlämningar och övriga kulturhistoriska
                        lämningar samt information som rör arbeten i närheten av
                        dessa miljöer. Riksantikvarieämbetet ansvarar för
                        Fornsök, men du kan också nå tjänsten via
                        länsstyrelsernas webbplatser.
                      </p>
                      <p>
                        <em>WebbGIS</em>
                      </p>
                      <p>
                        Via det geografiska informationsverktyget{" "}
                        <a href="http://projektwebbar.lansstyrelsen.se/gis/Sv/Pages/karttjanster.aspx">
                          WebbGIS
                        </a>{" "}
                        hittar du information inom olika ämnesområden som
                        exempelvis naturvård, miljöpåverkan och kulturmiljövård.
                        Du kan själv kryssa i de ämnesområden och grupper som du
                        är intresserad av. Det kan till exempel vara skyddad
                        natur, biotopskyddsområden, fornlämningar eller
                        miljöfarlig verksamhet. Här ges en samlad bild av
                        situationen i respektive län. Länsstyrelserna ansvarar
                        för WebbGIS och du hittar den via kartjänster på våra
                        respektive webbplatser.
                      </p>
                      <p>
                        <em>
                          Behövs det anmälan, tillstånd eller dispens för att få
                          gräva?
                        </em>
                      </p>
                      <p>
                        Beroende på vilken typ av mark du planerar att gräva i
                        och vad som finns i området kan det behövas en anmälan
                        om samråd eller ansökan om tillstånd eller dispens.
                      </p>
                      <p>
                        <span>Skyddad natur </span>
                      </p>
                      <p>
                        Grävningar i skyddad natur kräver dispens eller
                        tillstånd från länsstyrelsen eller kommunen. På
                        länsstyrelsernas webbplatser finns mer information om
                        skyddade områden som exempelvis reservat, alléer eller
                        stenmurar. Här finns också information om tillstånd och
                        dispenser.
                      </p>
                      <p>
                        <span>Fornlämningar och kulturmiljöer</span>
                      </p>
                      <p>
                        Fornlämningar och vissa kulturmiljöer är skyddade enligt
                        svensk lag. Om grävningen kommer att påverka dessa
                        miljöer krävs det i regel en ansökan. På
                        länsstyrelsernas webbplatser finns mer information om
                        fornlämningar och kulturmiljöer. Här finns också
                        ansökningsblanketter.&nbsp;
                      </p>
                      <p>
                        <em>Förorenade områden</em>
                      </p>
                      <p>
                        Om grävningen för bredband är planerad i ett område där
                        det funnits miljöfarlig verksamhet eller om det finns
                        misstanke om att området är förorenat behöver du
                        kontakta länsstyrelsen eller kommunen. De är
                        tillsynsmyndighet beroende på vilken tidigare verksamhet
                        som funnits på platsen. Mer information kan du få av din
                        länsstyrelse eller kommun.
                      </p>
                      <p>
                        <em>Grävning i vatten eller nära strandlinje</em>
                      </p>
                      <p>
                        Om grävningen innebär att gräva i vatten behöver du
                        anmäla det till en tillsynsmyndighet, i normalfallet
                        länsstyrelsen i ditt län.
                      </p>
                      <p>
                        För att göra grävningar i närheten av vatten kan det
                        även krävas strandskyddsdispens. Strandskyddet varierar
                        i landet och kan vara från 100 meter till 300 meter upp
                        på land och ut i vattnet från strandlinjen.
                      </p>
                      <p>
                        På länsstyrelsernas webbplatser finns mer information om
                        vad som gäller för grävning i anslutning till vatten och
                        stränder.
                      </p>
                      <p>
                        <em>Naturmiljön i övrigt (12:6 samråd)</em>
                      </p>
                      <p>
                        Verksamhet eller åtgärder som väsentligt kommer att
                        ändra naturmiljön och som inte omfattas av tillstånds-
                        eller anmälningsplikt enligt andra bestämmelser i
                        Miljöbalken (det vill säga sådant som inte gäller,
                        fornlämning, vattenmiljö eller skyddade områden), kräver
                        så kallat 12:6 samråd. Du kan läsa mer om denna typ av
                        samråd på länsstyrelsernas webbplatser.
                      </p>
                      <p>
                        <em>Mer information på länsstyrelsernas webbplatser</em>
                      </p>
                      <p>
                        På länsstyrelsernas respektive webbplatser hittar du mer
                        information om fornlämningar, kulturmiljöer,
                        vattenområden, skyddad natur och annat som kan vara till
                        hjälp i förberedelsernas inför en grävning för
                        bredbandsnät.
                      </p>
                      <p>
                        <span>Tydliga kartor</span>
                      </p>
                      <p>
                        För att skynda på och förenkla handläggningen av ärendet
                        är det bra att skicka in tydliga kartor där sträckningen
                        för var grävningen ska ske. Kartan får gärna vara i stor
                        skala, som 1:5000, då är det enklare och snabbare för
                        Länsstyrelsen att arbeta med din ansökan. Om
                        sträckningen går över ett stort område är det bra att
                        skicka med en översiktskarta och detaljerade storskaliga
                        kartor över delsträckningar.
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

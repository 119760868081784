import DOMPurify from "dompurify"

import Button from "react-bootstrap/Button"

import Card from "react-bootstrap/Card"
import "./style.css"

const cleanText = (text: string) => DOMPurify.sanitize(text)

export const CardComponent = ({
  title,
  description,
  image,
  link,
  linkFunc
}: any) => {
  return (
    <>
      <Card onClick={() => linkFunc(link)}>
        <Card.Img variant="top" src={image} alt={title} />
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text>
            <span
              dangerouslySetInnerHTML={{ __html: cleanText(description) }}
            />{" "}
            <p>
              <a>Läs mer om {title.toLowerCase()}</a>
            </p>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  )
}

export default CardComponent

import { Col, Row } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import Offcanvas from "react-bootstrap/Offcanvas"
import NavbarToggle from "react-bootstrap/NavbarToggle"
import { Link, useNavigate, useNavigation } from "react-router-dom"

import "./style.css"
import { link } from "fs"

function OffcanvasNavigation({
  breakpoint = false, //Bootstrap breakpoints: false, "sm", "md", "lg", "xl", "xxl"
  nameOfService,
  links,
  includeSearch = false,
  fluid = false
}: {
  breakpoint?: string | boolean
  nameOfService?: string
  links?: any
  includeSearch?: boolean
  fluid?: boolean
}) {
  const navigate = useNavigate()

  const linkClick = (link: string) => {
    navigate(link)
  }

  return (
    <>
      {[breakpoint].map((expand, index) => (
        <Navbar key={index} expand={expand} className="bg-body-tertiary mb-3">
          <Container fluid={fluid}>
            {nameOfService && <Link to="/">{nameOfService}</Link>}
            {!nameOfService && <>&nbsp;</>}
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />

            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  {nameOfService && <Link to="/">{nameOfService}</Link>}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  {links &&
                    links.map(({ label, href, children }: any) => (
                      <div className="navItem">
                        {!children && (
                          <NavbarToggle as="div">
                            <Link to={href}>{label}</Link>
                          </NavbarToggle>
                        )}

                        {children && (
                          <>
                            <Navbar.Toggle
                              as="div"
                              aria-controls={`offcanvasNavbar-expand-${expand}`}
                              className="nav-linkDropdown"
                            >
                              <NavDropdown
                                title={label}
                                id={`offcanvasNavbarDropdown-expand-${expand}`}
                                onClick={() => linkClick(href)}
                              >
                                <NavDropdown.Item>
                                  <NavbarToggle as="div">
                                    <Link to={href}>{label}</Link>
                                  </NavbarToggle>
                                </NavDropdown.Item>
                                {children.length > 0 &&
                                  children.map(({ label, href }: any) => (
                                    <NavDropdown.Item>
                                      <NavbarToggle as="div">
                                        <Link to={href}>{label}</Link>
                                      </NavbarToggle>
                                    </NavDropdown.Item>
                                  ))}
                              </NavDropdown>
                            </Navbar.Toggle>
                          </>
                        )}
                      </div>
                    ))}
                </Nav>
                {includeSearch && (
                  <Form className="d-flex">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="me-2"
                      aria-label="Search"
                    />
                    <Button variant="outline-success">Search</Button>
                  </Form>
                )}
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  )
}

export default OffcanvasNavigation

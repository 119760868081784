import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Övriga tillståndsgivare</h1>
        <div className="ingress"></div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        Utöver tillstånd från kommunen, lantmäteriet,
                        länsstyrelsen och trafikverket kan en bredbandsutbyggare
                        behöva vända sig andra aktörer för att ansöka om
                        tillstånd eller sluta avtal.
                      </p>
                      <p>
                        <em>Polisen</em>
                      </p>
                      <p>
                        I vissa fall kan det krävas tillstånd enligt
                        ordningslagen för ledning på allmän plats. Även i
                        samband med anläggandet kan det krävas tillstånd för
                        t.ex. byggställningar. Mer information hittar du på{" "}
                        <a
                          rel="noopener"
                          href="https://polisen.se/tjanster-tillstand/tillstand-ansok/offentlig-plats/"
                          target="_blank"
                          title="Polismyndighetens hemsidsa"
                        >
                          Polismyndighetens hemsida
                        </a>
                        .
                      </p>
                      <p>
                        <em>Fastighetsägare</em>
                      </p>
                      <p>
                        Om bredbandsutbyggnaden&nbsp;innebär att annans
                        fastighet måste användas på något sätt&nbsp;kan
                        ett&nbsp;nyttjanderättsavtal behöva ingås med
                        fastighetens ägare.&nbsp;&nbsp;
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

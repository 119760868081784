import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Infrastruktur</h1>
        <div className="ingress">
          Utbyggnadslagen har betydelse för aktörer både inom och utom
          telekomsektorn.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        Infrastruktur som omfattas av reglerna är passiva
                        nätdelar som är avsedda att rymma andra nätdelar utan
                        att själva bli aktiva, såsom kanalisationer, master,
                        tillsynsbrunnar, manhål, kopplingsskåp, byggnader,
                        ingångar till byggnader, antenninstallationer, torn och
                        stolpar.
                      </p>
                      <p>
                        Dricksvattenledningar och vissa andra nätdelar, såsom
                        kablar och svart fiber är undantagna.
                      </p>
                      <p>
                        Dessutom omfattas passiv infrastruktur för elektronisk
                        kommunikation i byggnader, se under{" "}
                        <Link to="/tilltraede/infrastruktur-i-byggnad/">
                          tillträde
                        </Link>
                        .
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text"></div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

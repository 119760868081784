import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Samordning</h1>
        <div className="ingress">
          Offentliga organ, eller den som utför ett bygg- eller
          anläggningsprojekt helt eller delvis finansierat med offentliga medel,
          är skyldiga att på begäran medge samordning av bygg- och
          anläggningsprojekt.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        Offentliga organ, samt andra nätinnehavare som utför ett
                        bygg- eller anläggningsprojekt helt eller delvis
                        finansierat med offentliga medel, är skyldiga att medge
                        samordning av det projektet och utbyggnad av fast eller
                        trådlöst bredband med kapacitet över 30 Mbit/s.
                        Skyldigheten att&nbsp;medge samordning&nbsp;gäller inte
                        när nätinnehavarens bygg- och anläggningsprojekt
                        är&nbsp;av mindre betydelse.
                      </p>
                      <p>
                        Samordning kan dels vara samförläggning, vilket innebär
                        att två rör läggs i samma schakt, dels innebära
                        samarbete kring själva arbetet. Samarbetet kan bestå av
                        nyttjande av gemensam arbetsväg eller samma maskiner.
                      </p>
                      <p>
                        En begäran om samordning ska vara skriftlig (kan vara
                        e-post) och innehålla en redogörelse för det planerade
                        bredbandsprojektet, vilket bygg- eller
                        anläggningsprojekt begäran avser och vilken typ av
                        samordning som krävs. Redogörelsen ska dessutom
                        innehålla allt som kan vara relevant för nätinnehavarens
                        bedömning av om det föreligger en samordningsskyldighet.
                        Begäran ska lämnas till nätinnehavaren senast en månad
                        innan denne måste ansöka om nödvändiga tillstånd hos
                        myndigheter.&nbsp;Bredbandsutbyggaren bör begära ett
                        mottagningsbevis för att försäkra sig om att tidsfristen
                        för nätinnehavarens svar börjar löpa.
                      </p>
                      <p>
                        Om villkor ställs upp för samordningen ska de&nbsp;vara
                        rättvisa, icke-diskriminerande och öppet
                        redovisade.&nbsp;
                      </p>
                      <p>
                        Nätinnehavaren får neka samordning om begäran om
                        samordning inte är rimlig. Exempel på när en begäran
                        inte är rimlig kan vara krav på driftsäkerhet eller
                        merkostnader för nätinnehavaren som bredbandsutbyggaren
                        inte är villig att ersätta nätinnehavaren för.
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

import { Link } from "react-router-dom";

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Lagar och regler</h1>
        <div className="ingress">
          Här finns länkar till proposition, lagtexter och det direktiv som
          lagen (2016:534) om åtgärder för utbyggnad av bredbandsnät bygger på.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32024R1309 ">
                          EUROPAPARLAMENTETS OCH RÅDETS FÖRORDNING (EU)
                          2024/1309{" "}
                        </a>
                        av den 29 april 2024 om åtgärder för att minska
                        kostnaderna för utbyggnad av gigabitnät för elektronisk
                        kommunikation, om ändring av förordning (EU) 2015/2120
                        och om upphävande av direktiv 2014/61/EU
                        (Gigabitinfrastrukturförordningen).
                      </p>
                      <p>
                        <a href="https://www.riksdagen.se/sv/dokument-lagar/dokument/svensk-forfattningssamling/lag-2016534-om-atgarder-for-utbyggnad-av_sfs-2016-534">
                          Lag (2016:534) om åtgärder för utbyggnad av
                          bredbandsnät{" "}
                        </a>
                      </p>
                      <p>
                        <a href="https://www.riksdagen.se/sv/dokument-och-lagar/dokument/svensk-forfattningssamling/lag-2022482-om-elektronisk-kommunikation_sfs-2022-482/">
                          Lag (2022:482) om elektronisk kommunikation
                        </a>
                      </p>
                      <p>
                        Regeringens{" "}
                        <a
                          href="http://www.regeringen.se/contentassets/e5dc98b0e08c4590986ff12999b6f18b/billigare-utbyggnad-av-bredbandsnat-prop.-20151673"
                          title="Regeringens proposition"
                        >
                          proposition 2015/16:73 Billigare utbyggnad av
                          bredbandsnät
                        </a>
                        .
                      </p>
                      <p>
                        <a
                          href="http://eur-lex.europa.eu/legal-content/SV/TXT/PDF/?uri=CELEX:32014L0061&amp;from=SV"
                          title="Direktivet"
                        >
                          Europaparlamentets och rådets direktiv 2014/61/EU{" "}
                        </a>
                        av den 15 maj 2014 om åtgärder för att minska
                        kostnaderna för utbyggnad av höghastighetsnät för
                        elektronisk kommunikation.
                      </p>
                      <p>
                        PTS har även information om{" "}
                        <a href="http://pts.se/sv/Bransch/Regler/Tvistlosning/">
                          tvistlösning
                        </a>
                        ,{" "}
                        <a href="http://pts.se/sv/Bransch/Regler/Tillsyn/">
                          tillsyn{" "}
                        </a>
                        och viss{" "}
                        <a href="http://pts.se/sv/Bransch/Internet/Utbyggnad-av-bredbandsnat/">
                          information
                        </a>{" "}
                        rörande lagen.
                      </p>
                      <p>
                        <em>Ändringar och tillägg i andra lagar</em>
                      </p>
                      <p>
                        Direktivet har föranlett ändringar i andra lagar utöver
                        de som listas ovan. Dessa är väglagen,
                        ledningsrättslagen och plan- och bygglagen.{" "}
                        <strong>
                          <br />
                        </strong>
                      </p>
                      <p>
                        I{" "}
                        <a href="https://www.riksdagen.se/sv/dokument-lagar/dokument/svensk-forfattningssamling/vaglag-1971948_sfs-1971-948">
                          väglagen (1971:948)
                        </a>{" "}
                        har en ändring införts att om tillstånd begärs för
                        ledningar som ska ingå i ett elektroniskt
                        kommunikationsnät för allmänt ändamål ska
                        väghållningsmyndigheten avgöra ärendet inom fyra månader
                        från det att ansökan kom in till myndigheten.
                      </p>
                      <p>
                        En ändring i{" "}
                        <a href="https://www.riksdagen.se/sv/dokument-lagar/dokument/svensk-forfattningssamling/ledningsrattslag-19731144_sfs-1973-1144">
                          ledningsrättslagen (1973:1144)
                        </a>{" "}
                        gör det möjligt att med stöd av en andrahandsupplåtelse
                        dra fram en ledning i tom kanalisation. Dessutom har ett
                        tillägg gjorts om att vid en förrättning som avser
                        ledning som ingår i ett elektroniskt kommunikationsnät
                        för allmänt ändamål eller annan anordning enligt 2a §
                        ledningsrättslagen så ska ledningsbeslut, beslut om
                        tillträde eller beslut om inställande av förrättning
                        meddelas inom fyra månader.&nbsp;
                      </p>
                      <p>
                        <a href="https://www.riksdagen.se/sv/dokument-lagar/dokument/svensk-forfattningssamling/plan--och-bygglag-2010900_sfs-2010-900">
                          Plan- och bygglagen (2010:900)
                        </a>{" "}
                        har ändrats genom att de tekniska egenskaper som är
                        väsentliga i fråga om bredbandsanslutning har lagts till
                        i listan över de tekniska egenskaper som ett
                        byggnadsverk ska ha. Med kravet på bredbandsanslutning
                        avses att ett byggnadsverk ska vara utformat och
                        utrustat för att möjliggöra anslutning till ett
                        elektroniskt kommunikationsnät som kan leverera tjänster
                        för bredbandstillgång med hög kapacitet. Ändringen
                        träder i kraft den 1 januari 2017.
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Page;

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

//import Card from "../Card/Card"
import Card from "../Card/Card"

import "./style.css"

const CardList = ({ data: { cards }, linkFunc }: any) => {
  return (
    <>
      <Container fluid="lg">
        <Row>
          {cards &&
            cards.map((card: any, index: any) => (
              <Col>
                <Card key={index} {...card} linkFunc={linkFunc} />
              </Col>
            ))}
        </Row>
      </Container>
    </>
  )
}

export default CardList

import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Begäran och svar</h1>
        <div className="ingress">
          Utbyggnadslagen anger att den som önskar nyttja rättigheter ska lämna
          en skriftlig begäran.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        Bredbandsutbyggare som önskar nyttja rättigheter enligt
                        lagen ska lämna en skriftlig begäran till
                        nätinnehavaren. Det kan denne göra genom att till
                        exempel&nbsp;skicka ett brev eller e-post. Oavsett hur
                        bredbandsutbyggaren väljer att lämna den skriftliga
                        begäran är denne ansvarig för att den kommer fram till
                        nätinnehavaren. Det sker enklast genom att be
                        nätinnehavaren bekräfta, till exempel&nbsp;genom ett
                        mottagningsbevis. Detsamma gäller när nätinnehavaren
                        skickar information eller ett medgivande eller avslag på
                        en begäran.
                      </p>
                      <p>
                        Att försäkra sig om att en begäran eller ett
                        svar&nbsp;har mottagits är viktigt för att de
                        tidsfrister som gäller för till
                        exempel&nbsp;nätinnehavarens besked om medgivande eller
                        avslag ska börja löpa.
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

import { exit } from "process"
import { useState } from "react"
import { Link, useLocation } from "react-router-dom"

import "./style.css"

function SubMenu({ links }: { links?: any }) {
  const location = useLocation()
  const path = location.pathname

  let MenuNode: any = []

  if (location.pathname == "/") {
    MenuNode = links
  }

  links &&
    links.map(({ href, children }: any) => {
      if (href === location.pathname) {
        //setMenuNode(links)
        //MenuNode = links
      }
      if (children && href === location.pathname) {
        MenuNode = children
      }
      children &&
        children.map(({ href }: any) => {
          //alert(href + " " + location.pathname)
          if (href === location.pathname) {
            //setMenuNode(children)
            MenuNode = children
          }
        })
    })
  // console.log("MenuNode: " + MenuNode)

  return (
    <>
      {MenuNode.length > 0 && (
        <ul>
          {MenuNode.map(({ href, label }: any, index: any) => {
            if (href === location.pathname) {
              return (
                <li className="active" key={index}>
                  <Link to={href}>{label}</Link>
                </li>
              )
            }
            return (
              <li key={index}>
                <Link to={href}>{label}</Link>
              </li>
            )
          })}
        </ul>
      )}
    </>
  )
}

export default SubMenu

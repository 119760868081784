import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Frågor och svar</h1>
        <div className="ingress">
          Här hittar du svar på några av de vanligaste frågorna om lagen om
          åtgärder för utbyggnad av bredbandsnät.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>&nbsp;</p>
                      <p>
                        <em>
                          Vem ansvarar för tillstånd och relevanta avtal, till
                          exempel&nbsp;markavtal?
                        </em>
                      </p>
                      <p>
                        Bredbandutbyggaren ansvarar i första hand för att ansöka
                        om tillstånd och träffa avtal. När det krävs (till
                        exempel&nbsp;när det uppställs formella krav på att det
                        är nätinnehavaren som ska underteckna ansökan om
                        tillstånd) är dock nätinnehavaren skyldig att medverka
                        genom att söka tillståndet eller ingå avtalet med berörd
                        aktör.<strong>&nbsp;</strong>
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <em>
                          Vad gäller om den sista dagen i en tidsfrist är en
                          lördag, söndag, helgdagsafton eller helgdag?&nbsp;
                        </em>
                      </p>
                      <p>
                        Enligt&nbsp;lagen (1930:173) om beräkning av lagstadgad
                        tid blir då&nbsp;sista dagen i en tidsfrist nästkommande
                        vardag.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <em>
                          Vad händer om nätinnehavaren underlåter att svara?
                        </em>
                      </p>
                      <p>
                        Om nätinnehavaren trots flera uppmaningar inte besvarar
                        en begäran kan det betraktas som att denne nekar en
                        begäran om information, tillträde eller samordning.
                        Bredbandsutbyggaren kan då vända sig till PTS för
                        tvistlösning.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <em>
                          Kan vi i egenskap av byalag använda oss av lagen för
                          att begära tillträde?
                        </em>
                      </p>
                      <p>
                        Ja, eftersom byalag har för avsikt att anlägga eller
                        bygga ut ett bredbandsnät&nbsp;kan de nyttja lagen för
                        detta ändamål.
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>PTS beslut</h1>
        <div className="ingress">
          Här hittar du PTS beslut i tillsyn och tvistlösningar.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <h2>
                        <strong>Tillsyn</strong>
                      </h2>
                      <h3>Telia&nbsp;17-9513</h3>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <strong>
                          PTS inledde på eget initiativ tillsyn för att utreda
                          om Telia Company AB (Telia) följer krav i
                          utbyggnadslagen om att ta emot och besvara en begäran
                          om tillträde till fysisk infrastruktur. Enligt lagens
                          krav ska en begäran om tillträde bl.a. besvaras inom
                          två månader.
                        </strong>
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        I tillsynen framkom att Telia tillämpade rutiner som
                        bl.a. innebar att en bredbandsutbyggare först behövde
                        hämta information i Ledningskollen innan en begäran om
                        tillträde ingavs, eller att en begäran skulle ges in på
                        en särskild blankett. Sammantaget bedömde PTS att Telias
                        rutiner för mottagande av begäran om tillträde inte var
                        förenliga med bestämmelserna i 2 kap. 1 och 3 §§
                        utbyggnadslagen. Den 3 maj 2018 förelade PTS därför
                        Telia att vidta åtgärder för att ta emot och behandla
                        varje begäran om tillträde som lämnas in direkt till
                        någon av bolagets sedvanliga kontaktvägar, och i samband
                        därmed, upphöra att ställa som krav att den som begär
                        tillträde måste begära information eller vidta andra
                        åtgärder innan Telia behandlar begäran eller att en
                        begäran om tillträde ska göras på en särskild blankett.
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        PTS beslut om föreläggande överklagades till
                        Förvaltningsrätten i Stockholm. Förvaltningsrätten
                        avslog överklagandet den 14 mars 2019. Domen
                        överklagades inte.
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <a
                          rel="noopener"
                          href="/media/1074/beslut-i-arende-17-9513_180503.pdf"
                          target="_blank"
                          title="PTS beslut"
                        >
                          PTS beslut
                        </a>
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <a
                          rel="noopener"
                          href="/media/1075/stockholm-fr-12042-18-dom-2019-03-14-002.pdf"
                          target="_blank"
                          title="Dom förvaltningsrätten"
                        >
                          Dom förvaltningsrätten
                        </a>
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        &nbsp;
                      </p>
                      <h2>
                        <strong>Tvistlösning</strong>
                      </h2>
                      <h3>
                        Stiftelsen Chalmers studenthem ./. Skanova AB 21-2463
                      </h3>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <strong>
                          Tvistlösningen mellan Stiftelsen Chalmers studenthem
                          (SCS) och Skanova AB rörande tillträde till Skanovas
                          kanalisation enligt 2 kap. 1 § samt undersökning och
                          information enligt 4 kap. 2 § och 3 § lagen (2016:534)
                          om åtgärder för utbyggnad av bredbandsnät
                          (utbyggnadslagen).
                        </strong>
                      </p>
                      <p>
                        Efter att Skanova AB avslagit SCS:s begäran om tillträde
                        till kanalisation vände sig SCS till PTS med en begäran
                        om tvistlösning. PTS beslutade den 1 juni 2021 att
                        återförvisa begäran om tillträde och begäran om
                        information till Skanova AB för fortsatt prövning.
                      </p>
                      <p>
                        När det gäller begäran om undersökning anmodar PTS
                        parterna att komma överens om detaljerna.
                      </p>
                      <p>PTS beslut överklagades inte.</p>
                      <p>
                        <a
                          rel="noopener"
                          href="/media/1096/beslut-21-2463.pdf"
                          target="_blank"
                          title="PTS beslut."
                        >
                          PTS beslut
                        </a>
                      </p>
                      <h3>
                        Maj-Söröje-Bölom Byafiber Ekonomisk förening ./. E.ON
                        Energidistribution AB 21-1149
                      </h3>
                      <p>
                        <strong>
                          Tvistlösning mellan Maj-Söröje-Bölom Byafiber
                          Ekonomisk förening och E.ON Energidistribution AB
                          rörande tillträde enligt 2 kap. 1 § lagen (2016:534)
                          om åtgärder för utbyggnad av bredbandsnät
                          (utbyggnadslagen).&nbsp;
                        </strong>
                      </p>
                      <p>
                        Efter att E.ON Energidistribution AB (E.ON) avslagit MSB
                        Byafibers begäran om tillträde till E.ON:s stolpar vände
                        sig MSB Byafiber till PTS med en begäran om
                        tvistlösning. PTS beslutade den 9 juni 2021 att E.ON på
                        rättvisa och rimliga villkor ska medge MSB Byafiber
                        tillträde till E.ON:s stolpar på viss angiven sträcka.
                      </p>
                      <p>PTS beslut överklagades inte.</p>
                      <p>
                        <a
                          data-id="1259"
                          href="/media/1094/beslut-21-1149.pdf"
                          title="Beslut 21 1149"
                        >
                          PTS beslut
                        </a>
                      </p>
                      <h3>Skanova AB ./. Trafikverket 19-5109</h3>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <span>
                          <strong>
                            Tvist<span>lösning</span> mellan Skanova AB och
                            Trafikverket rörande samordning av projekt enligt 3
                            kap. 1 § lagen (2016:534) om åtgärder för utbyggnad
                            av bredbandsnät (utbyggnadslagen).{" "}
                          </strong>
                        </span>
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        Efter att Trafikverket avslagit en begäran om samordning
                        från Skanova AB begärde Skanova AB att PTS skulle pröva
                        tvisten. PTS beslutade den 9 september 2019 att
                        Trafikverket ska samordna sina bygg- och
                        anläggningsprojekt på en viss järnvägssträcka med
                        Skanova AB:s projekt för anläggande av optisk fiberkabel
                        inklusive längsgående och tvärgående kanalisation på
                        samma sträcka (PTS dnr 19-5109).
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        PTS beslut överklagades till Förvaltningsrätten i
                        Stockholm. Förvaltningsrätten avslog Trafikverkets
                        överklagande i dom den 20 februari 2020 (mål nr
                        21800-19). Trafikverket överklagade domen till
                        Kammarrätten i Stockholm (mål nr 1918-20) som upphävde
                        Förvaltningsrättens dom och PTS beslut. Kammarrättens
                        dom är överklagad till Högsta förvaltningsdomstolen.
                      </p>
                      <p>
                        I domen konstaterar Kammarrätten att det aktuella bygg-
                        och anläggningsprojektet i vart fall delvis avser fysisk
                        infrastruktur som stödjer samhällsviktig verksamhet. Den
                        aktuella undantagsbestämmelsen är därför tillämplig om
                        verksamhetens funktion kan påverkas negativt av en
                        samordning. Någon prövning av huruvida den negativa
                        påverkan kan undvikas genom villkor ska inte göras.
                        Kammarrätten anser att Trafikverket på ett godtagbart
                        sätt har redogjort för varför en samordning påverkar
                        bygg- och anläggningsprojektet och därigenom gjort
                        sannolikt att en samordning av de i målet aktuella bygg-
                        och anläggningsprojekten kan påverka verksamhetens
                        funktion negativt. Projektet är därför undantaget från
                        samordningsskyldigheten och Skanovas begäran kan inte
                        medges.​ Efter att Skanova överklagat till Högsta
                        förvaltningsdomstolen beslutade domstolen den 23
                        november 2021 att inte meddela prövningstillstånd (mål
                        nr 7231-20).
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <a
                          rel="noopener"
                          data-id="1231"
                          href="/media/1076/beslut-19-5109-190909-422406-0-_tmp.pdf"
                          target="_blank"
                          title="beslut 19-5109 190909(422406) (0)_TMP.pdf"
                        >
                          PTS beslut
                        </a>
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <a
                          data-id="1254"
                          href="/media/1092/stockholm-fr-21800-19-dom-2020-02-20.pdf"
                          title="Stockholm FR 21800-19 Dom 2020-02-20.pdf"
                        >
                          Dom förvaltningsrätten
                        </a>
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <a
                          rel="noopener"
                          data-id="1257"
                          href="/media/1093/stockholm-kr-1918-20-dom-2020-11-27.pdf"
                          target="_blank"
                          title="Stockholm KR 1918 20 Dom 2020 11 27"
                        >
                          Dom kammarrätten
                        </a>
                      </p>
                      <h3>
                        Engelnet ekonomisk förening ./. VB Energi AB 18-3651
                      </h3>
                      <p>
                        <strong>
                          Tvistlösning mellan Engelnet ekonomisk förening och VB
                          Energi AB rörande tillträde enligt 2 kap. 1 § lagen
                          (2016:534) om åtgärder för utbyggnad av bredbandsnät
                          (utbyggnadslagen).{" "}
                        </strong>
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        Efter att VB Energi avslagit Engelnet ekonomisk
                        förenings begäran om tillträde vände sig föreningen till
                        PTS med en begäran om tvistlösning. PTS beslutade den 4
                        juli 2018 att VB Energi AB ska medge Engelnet ekonomisk
                        förening tillträde till kanalisation som är anlagd under
                        järnväg och på bro vid vissa angivna platser (PTS dnr
                        18-3651).
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        PTS beslut överklagades inte.
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <a
                          data-id="1251"
                          href="/media/1090/tvist-engelnetvb-energi.pdf"
                          title="Tvist engelnet.vb energi .pdf"
                        >
                          PTS beslut
                        </a>
                        &nbsp;
                      </p>
                      <h3>Norsjö kommun ./. Skanova AB 17-6439</h3>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <span>
                          <strong>
                            Tvist<span>lösning</span> mellan Skanova AB och
                            Norsjö kommun rörande tillträde enligt 2 kap. 1 §
                            lagen (2016:534) om åtgärder för utbyggnad av
                            bredbandsnät (utbyggnadslagen).{" "}
                          </strong>
                        </span>
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        Efter att Skanova har avslagit en begäran från Norsjö
                        kommun om tillträde till Skanovas kanalisation begärde
                        Norsjö kommun att PTS skulle pröva tvisten. PTS
                        beslutade den 11 oktober 2017 (dnr 17-6439) att Skanova
                        AB på rättvisa och skäliga villkor ska medge Norsjö
                        kommun tillträde till kanalisation i form av
                        samutnyttjande.
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        PTS beslut överklagades till Förvaltningsrätten i
                        Stockholm som beslutade att avslå överklagan (mål nr
                        25366-17). Förvaltningsrätten fann att det inte
                        framkommit sådana omständigheter som medför att det av
                        Norsjö begärda tillträdet inte är rimligt, varför
                        överklagandet avslogs. Förvaltningsrättens dom
                        överklagades och både Kammarrätten i Stockholm (mål nr
                        3097-19) och Högsta förvaltningsdomstolen (mål nr
                        5743-19) har beslutat att inte meddela
                        prövningstillstånd. PTS beslut står därmed fast.
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <a
                          data-id="1250"
                          href="/media/1089/pts-beslut-20171011-17-6439-32.pdf"
                          title="PTS Beslut 20171011(17-6439-32).pdf"
                        >
                          PTS beslut
                        </a>
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <a
                          rel="noopener"
                          data-id="1252"
                          href="/media/1091/foervaltningsraetten-20190329-dom-17-10577-10.pdf"
                          target="_blank"
                          title="Förvaltningsrätten 20190329 Dom(17-10577-10).pdf"
                        >
                          Dom förvaltningsrätten
                        </a>
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <a
                          rel="noopener"
                          data-id="1248"
                          href="/media/1087/stockholm-kr-3097-19-slutligt-beslut-2019-09-25.pdf"
                          target="_blank"
                          title="Stockholm KR 3097-19 Slutligt beslut 2019-09-25.pdf"
                        >
                          Dom kammarrätten
                        </a>
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <a
                          rel="noopener"
                          data-id="1249"
                          href="/media/1088/hfd-5743-19-slutligt-beslut-2019-12-09-1.pdf"
                          target="_blank"
                          title="HFD 5743-19 Slutligt beslut 2019-12-09 (1).pdf"
                        >
                          Beslut Högsta förvaltningsdomstolen
                        </a>
                      </p>
                      <h3>IP-Only Networks AB ./. Övik Energi AB 17-8014</h3>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <strong>
                          Tvistlösning mellan IP-Only Networks AB och Övik
                          Energi AB rörande samordning enligt 3 kap 1 §{" "}
                          <span>
                            lagen (2016:534) om åtgärder för utbyggnad av
                            bredbandsnät (utbyggnadslagen).
                          </span>
                        </strong>
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        IP-Only har begärt att få samförlägga kanalisation med
                        Övik Energi i samband med en utbyggnad av ett
                        ortssammanbindande fibernät. Övik Energi har avslagit
                        IP-Onlys begäran med motiveringen att bolagets begäran
                        är för sent framställd, att den inte uppfyller de
                        formella kraven i utbyggnadslagen samt att den inte är
                        rimlig. IP-Only har därefter begärt att PTS ska lösa
                        tvisten mellan parterna. PTS beslutade den 14 september
                        2017 att Övik Energi ska medge IP-Only samordning med
                        undantag av de sträckor där Övik Energis arbete var
                        avslutat på beslutsdagen (PTS dnr 17-8014). Övik Energi
                        överklagade beslutet till Förvaltningsrätten i Stockholm
                        som den 14 juni 2018 avslog överklagandet (mål nr
                        22968-17). Domen har vunnit laga kraft.
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <a
                          data-id="1244"
                          href="/media/1085/besluttvistloesning-ip-only-oevik-energi-20170914.pdf"
                          title="BeslutTvistlösning IP-Only-Övik energi 20170914.pdf"
                        >
                          PTS beslut
                        </a>
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <a
                          rel="noopener"
                          data-id="1245"
                          href="/media/1086/stockholm-fr-22968-17-dom-2018-06-14.pdf"
                          target="_blank"
                          title="Stockholm FR 22968-17 Dom 2018-06-14.pdf"
                        >
                          Dom förvaltningsrätten
                        </a>
                      </p>
                      <h3>
                        Torsö Skärgårdsfiber ekonomisk förening ./. Skanova AB
                        17-6200
                      </h3>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <strong>
                          Tvistlösning mellan Torsö Skärgårdsfiber ekonomisk
                          förening och Skanova AB rörande undersökning av fysisk
                          infrastruktur och tillträde enligt 4 kap 3 § och 2 kap
                          1 §{" "}
                          <span>
                            lagen (2016:534) om åtgärder för utbyggnad av
                            bredbandsnät (utbyggnadslagen).
                          </span>
                        </strong>
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        Torsö Skärgårdsfiber har i begäran om tvistlösning yrkat
                        att få tillträde till Skanovas kanalisation och att få
                        information om eller möjlighet att undersöka
                        kanalisationen. PTS beslutade den 26 juli 2017 att
                        Skanova ska ge Skärgårdsfibers tillträde till
                        kanalisationen och även ge föreningen möjlighet att
                        undersöka den aktuella infrastrukturen (PTS dnr
                        17-6200). Skanova överklagade beslutet till
                        Förvaltningsrätten i Stockholm och begärde även att
                        domstolen skulle inhämta ett förhandsavgörande från
                        EU-domstolen. Förvaltningsrätten avslog den 29 mars 2019
                        överklagandet (mål nr 19273-17). Skanova överklagade
                        till Kammarrätten i Stockholm som den 25 september 2019
                        beslutade att inte meddela prövningstillstånd (mål nr
                        3133-19). Efter att Skanova överklagat till Högsta
                        förvaltningsdomstolen beslutade domstolen den 9 december
                        2019 att inte meddela prövningstillstånd (mål nr
                        5744-19).
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <a
                          data-id="1240"
                          href="/media/1082/torsoe-beslut-17-6200-306619-0-_tmp.pdf"
                          title="Torsö Beslut 17-6200(306619) (0)_TMP.pdf"
                        >
                          PTS beslut
                        </a>
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <a
                          rel="noopener"
                          data-id="1239"
                          href="/media/1081/stockholm-kr-3133-19-slutligt-beslut-2019-09-25.pdf"
                          target="_blank"
                          title="Stockholm KR 3133-19 Slutligt beslut 2019-09-25.pdf"
                        >
                          Dom förvaltningsrätten
                        </a>
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <a
                          rel="noopener"
                          data-id="1239"
                          href="/media/1081/stockholm-kr-3133-19-slutligt-beslut-2019-09-25.pdf"
                          target="_blank"
                          title="Stockholm KR 3133-19 Slutligt beslut 2019-09-25.pdf"
                        >
                          Dom kammarrätten
                        </a>
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <a
                          rel="noopener"
                          data-id="1241"
                          href="/media/1083/hfd-5743-19-slutligt-beslut-2019-12-09-1.pdf"
                          target="_blank"
                          title="HFD 5743-19 Slutligt beslut 2019-12-09 (1).pdf"
                        >
                          Beslut Högsta förvaltningsdomstolen
                        </a>
                      </p>
                      <h3>
                        Torptäppans Samfällighetsförening ./. TeliaSonera
                        Skanova Access AB 16-8191
                      </h3>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <strong>
                          Tvistlösning mellan Torptäppans Samfällighetsförening
                          och TeliaSonera Skanova Access AB rörande tillträde
                          enligt 2 kap 1 §{" "}
                          <span>
                            lagen (2016:534) om åtgärder för utbyggnad av
                            bredbandsnät (utbyggnadslagen).
                          </span>{" "}
                        </strong>
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        Efter att Skanova avslagit Torptäppans begäran om
                        tillträde till av Skanova ägd kanalisation inom
                        föreningens område begärde Torptäppan att PTS skulle
                        pröva tvisten. Torptäppan hade för avsikt att bygga ett
                        eget fastighetsnät med fiber för anslutning av bredband,
                        tv och telefoni för att därefter ansluta föreningen till
                        valfri operatör. PTS beslutade den 11 november 2016 att
                        Skanova skulle ge Torptäppan tillträde till kanalisation
                        inom föreningens område (PTS dnr 16-8191). Skanova
                        överklagade beslutet till Förvaltningsrätten i Stockholm
                        som i dom den 21 november 2017 upphävde det överklagade
                        beslutet och återförvisade målet till PTS (mål nr
                        25854-16). Efter att både PTS och Torptäppan överklagat
                        domen till Kammarrätten i Stockholm biföll kammarrätten
                        överklagandet och fastställde den 29 januari 2019 PTS
                        beslut (mål nr 7531-17). Skanova överklagade därefter
                        till Högsta förvaltningsdomstolen och begärde även att
                        domstolen skulle inhämta ett förhandsavgörande från
                        EU-domstolen. Högsta förvaltningsdomstolen meddelade den
                        9 december 2019 beslut att inte inhämta
                        förhandsavgörande och meddelade inte
                        prövningstilltillstånd (mål nr 1375-19).
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <a
                          rel="noopener"
                          data-id="1234"
                          href="/media/1077/20161111-torptaeppan-beslut-pts-dnr-16-8191-002.pdf"
                          target="_blank"
                          title="20161111 Torptäppan Beslut PTS dnr 16-8191 (002).pdf"
                        >
                          PTS beslut
                        </a>
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <a
                          rel="noopener"
                          href="/media/1078/dom-fr-torptaeppan-171121-003.pdf"
                          target="_blank"
                          title="Dom FR Torptäppan 171121 (003).pdf"
                        >
                          Dom förvaltningsrätten
                        </a>
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <a
                          rel="noopener"
                          href="/media/1079/stockholm-kr-7531-17-dom-2019-01-29-003.pdf"
                          target="_blank"
                          title="Stockholm KR 7531-17 Dom 2019-01-29 (003).pdf"
                        >
                          Dom kammarrätten
                        </a>
                      </p>
                      <p
                        style={{
                          color: "#000000",
                          fontFamily: "Verdana,Arial,Helvetica,sans-serif",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontVariant: "normal",
                          fontWeight: "400",
                          letterSpacing: "normal",
                          orphans: "2",
                          textAlign: "left",
                          textDecoration: "none",
                          textIndent: "0px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          wordSpacing: "0px"
                        }}
                      >
                        <a
                          rel="noopener"
                          data-id="1237"
                          href="/media/1080/hfd-1375-19-slutligt-beslut-2019-12-09-1-002.pdf"
                          target="_blank"
                          title="HFD 1375-19 Slutligt beslut 2019-12-09 (1) (002).pdf"
                        >
                          Beslut Högsta förvaltningsdomstolen
                        </a>
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

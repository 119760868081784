import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Vilka omfattas av lagen </h1>
        <div className="ingress">
          Utbyggnadslagen medför rättigheter för bredbandsutbyggare och
          skyldigheter för nätinnehavare både inom och utom telekomsektorn.
          Nätinnehavare, som är ett offentligt organ eller nätinnehavare vars
          bygg- eller anläggningsprojekt helt eller delvis finansieras med
          offentliga medel, kan ha särskilda skyldigheter.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        <strong>Bredbandsutbyggare </strong>är den som har för
                        avsikt att anlägga eller bygga ut ett bredbandsnät. Det
                        kan vara en privat eller offentlig aktör och det finns
                        inget krav på att denne ska tillhandahålla ett allmänt
                        kommunikationsnät för att omfattas av lagen. Förutom
                        redan etablerade aktörer omfattas de som för första
                        gången ska anlägga ett nät, till exempel&nbsp;byalag.
                      </p>
                      <p>
                        <strong>Nätinnehavare </strong>är den som
                        tillhandahåller ett allmänt kommunikationsnät eller en
                        infrastruktur avsedd för att tillhandahålla en
                        transporttjänst eller en tjänst för produktion,
                        transport eller distribution av gas, el, uppvärmning
                        eller vatten. Nätinnehavare kan vara dels privata dels
                        offentliga aktörer, men eftersom infrastrukturen ska
                        vara avsedd för att tillhandahålla tjänster omfattas
                        inte till exempel&nbsp;gemensamhetsanläggningar som
                        enbart nyttjas av medlemmarna själva. För att anses vara
                        en nätinnehavare bör denne även driva någon form av
                        ekonomisk verksamhet, även om den inte behöver drivas i
                        vinstsyfte.
                      </p>
                      <p>
                        <strong>Offentligt organ</strong> är den, som utöver att
                        omfattas av kriterierna för att vara nätinnehavare, även
                        är en sådan aktör som anges i 1 kap 22 § lagen
                        (2016:1145) om offentlig upphandling, till
                        exempel&nbsp;statliga och kommunala myndigheter och
                        kommunala bolag.&nbsp;
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

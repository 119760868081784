import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Mer information om bredbandsutbyggnad</h1>
        <div className="ingress">
          Här finns information om att bygga ut bredband.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        Lagen&nbsp;(2016:534) om åtgärder för utbyggnad av
                        bredbandsnät syftar till att främja bredbandsutbyggnad.
                        Den ska leda till sänkta kostnader för utbyggnad av
                        bredband och att fler i Sverige får tillgång till snabbt
                        bredband.
                      </p>
                      <p>
                        Det görs även en hel del annat inom bredbandsområdet, en
                        del av det listas här.
                        <br />
                        <br />
                        Regeringens{" "}
                        <a
                          href="http://bredbandsforum.se/"
                          title="Bredbandsforum"
                        >
                          Bredbandsforum
                        </a>{" "}
                        främjar samverkan kring bredbandsutbyggnad. Forumet har
                        mycket information om hur man bygger ut bredband.
                        <br />
                        <br />
                        PTS arbetar också för att stärka skyddet av elektroniska
                        kommunikationer och för att kommunikationerna ska vara
                        robusta, på PTS webbplats finns information om{" "}
                        <a
                          rel="noopener"
                          href="https://www.pts.se/sv/bransch/internet/Om-robust-kommunikation/"
                          target="_blank"
                          title="PTS robusthet"
                        >
                          PTS robusthetsarbete
                        </a>
                        . Det finns även mer&nbsp;
                        <a
                          rel="noopener"
                          href="http://robustfiber.se/"
                          target="_blank"
                          title="http://robustfiber.se/"
                        >
                          vägledning och allmän information&nbsp;
                        </a>
                        för dig som vill bygga&nbsp;robust fibernät.&nbsp;
                      </p>
                      <p>
                        PTS har information om&nbsp;
                        <a
                          rel="noopener"
                          href="https://www.pts.se/bredbandsutbyggnad"
                          target="_blank"
                          title="Bredbandsutbyggnad"
                        >
                          bredbandsutbyggnad
                        </a>{" "}
                        - om hur man bygger ut bredband, vilka aktörer som kan
                        vara inblandade och hur man bäst kan göra. <br />
                        <br />
                        <a href="http://www.verksamt.se" title="Verksamt.se">
                          Verksamt.se
                        </a>{" "}
                        är en e-tjänst för den som funderar på att starta ska
                        starta, redan driver, vill utveckla eller ska avveckla
                        ett företag. På verksamt.se finns nyttig information om
                        företagande i allmänhet. Det finns information,
                        räkneverktyg och länkar till fördjupad information på
                        andra webbplatser. Det går att logga in med
                        e-legitimation i e-tjänster och bland annat registrera
                        företag, ändra företagsnamn, ansöka om godkännande för
                        F-skatt och skapa en personlig affärsplan.{" "}
                        <a href="http://www.verksamt.se" title="Verksamt.se">
                          Verksamt.se
                        </a>{" "}
                        är ett samarbete mellan Bolagsverket, Skatteverket och
                        Tillväxtverket.
                        <br />
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

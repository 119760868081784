import { Link } from "react-router-dom"

function Page() {
  return (
    <>
      <div className="col-md-9">
        <h1>Infrastruktur utanför byggnad</h1>
        <div className="ingress">
          Nätinnehavare är skyldiga att på begäran medge tillträde till fysisk
          infrastruktur utanför byggnad.
        </div>

        <div className="umb-grid">
          <div className="grid-section">
            <div>
              <span className="links">
                <div className="row">
                  <div className="col-sm-8 col-md-12">
                    <div className="text">
                      <p>
                        Tillträde, dvs. möjlighet att anlägga bredbandsnät, kan
                        medges till passiva nätdelar som kanalisationer, master,
                        tillsynsbrunnar, kopplingsskåp, och&nbsp;stolpar.
                        Infrastruktur för dricksvatten och nätdelar såsom kablar
                        och svart fiber omfattas dock inte.
                      </p>
                      <p>
                        När bredbandsutbyggnaden kräver tillstånd ska
                        nätinnehavaren bistå bredbandsutbyggaren (till
                        exempel&nbsp;att formellt ansöka om tillståndet).
                      </p>
                      <p>
                        En begäran om tillträde till fysisk infrastruktur får
                        avslås om den inte är rimlig med hänsyn till exempelvis
                        teknisk lämplighet, utrymmesbrist eller om
                        nätinnehavaren erbjuder ett alternativt tillträde som
                        motsvarar bredbandsutbyggarens behov (till
                        exempel&nbsp;genom uthyrning av svart fiber). Likaså får
                        nätinnehavaren avslå en begäran om tillträde om
                        bredbandsutbyggaren har rätt att begära tillträde till
                        eller användning av den fysiska infrastrukturen enligt
                        beslut som har fattats med stöd av lag (2003:389) om
                        elektronisk kommunikation<em>.&nbsp;</em>
                        Bredbandsutbyggaren bör begära ett mottagningsbevis för
                        att försäkra sig om att tidsfristen för nätinnehavarens
                        svar börjar löpa.
                      </p>
                      <p>
                        Nätinnehavare får ställa upp villkor för tillträdet,
                        till exempel&nbsp;avseende ersättning
                        och&nbsp;skyddsåtgärder för att trygga driften eller
                        miljön.
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Page

import { Col, Container, Row } from "react-bootstrap";
import "./style2.css";
import { Link } from "react-router-dom";

const Footer2 = () => {
  return (
    <>
      <footer className="footer mt-auto py-3 bg-light">
        <Container>
          <Row>
            <Col lg="auto">
              <Link to="https://www.pts.se/kontakt/">Kontakta oss</Link>
            </Col>
            <Col lg="auto">
              <Link to="https://www.pts.se/om-oss/behandling-av-personuppgifter/">
                Behandling av personuppgifter
              </Link>
            </Col>
            <Col lg>
              <Link to="https://www.pts.se/om-oss/om-pts.se/tillganglighetsredogorelse-for-pts-digitala-tjanster/">
                Tillgänglighetsredogörelse
              </Link>
            </Col>
            <Col lg>
              <Link to="https://www.pts.se/om-oss/om-pts.se/kakor-pa-webbplatsen/kakor-pa-pts-e-tjanster/">
                Kakor
              </Link>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer2;
